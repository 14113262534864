import React, { useContext, useEffect, useState } from "react";
import GovernmentContext from "./GovernmentContext";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import { Fetchdata } from "../../hooks/getData";
import $ from "jquery";

function GovernmentState(props) {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ToleID = userinfo.ToleID;
  const UserID = userinfo.UserID;
  const { setMsg, URL, urll } = useContext(SidebarContext);
  const apisignature = process.env.REACT_APP_SIGNATURE;

  const [popup, setPopup] = useState(false);
  const [editPop, setEditPop] = useState(false);
  const [loading, setLoading] = useState(true);
  const [newValue, setNewValue] = useState("member");

  const [governmentFormError, setGovernmentFormError] = useState({});
  const [governmentSubmit, setGovernmentSubmit] = useState(false);

  const [reload, setReload] = useState(false);

  const [editIsSubmit, setEditIsSubmit] = useState(false);
  const [closeChecked, setCloseChecked] = useState(false);
  

  const governmentvalue = {
    member: "",
    identype: "",
    identno: "",
    issuedate: "",
    issueby: "",
    date: "",
    isChecked: false,
  };
  const [governmentFormValue, setGovernmentFormValue] = useState(governmentvalue);


  const fetchdata = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "i",
      MemID:governmentFormValue.member,
      IdentType:governmentFormValue.identype,
      IdentNo:governmentFormValue.identno,
      IdentDate:governmentFormValue.issuedate,
      IdentBy:governmentFormValue.issueby,
      IsActive:"A",
    };
    // console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/gov-ident`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          // $(".complainTopicPop").fadeOut(100);
        setPopup(false);
          governmentTitleList();
          setMsg({
            msg: resp.Message,
            type: "alert alert-success",
          });
        } else {
          setMsg({
            msg: resp.Message,
            type: "alert alert-danger",
          });
        }
      });
    });
  };


  const [pushGovernment, setPushGovernment] = useState(false);

  const [chooseOption, setChooseOption] = useState("1");
  // console.log(chooseOption)

  const [chooseMember, setChooseMember] = useState("1");

  //Edit Notification
  const [titleID, setTitleId] = useState(null);
  const [date, setDate] = useState();

  const handleEditGovernment = (datas) => {
    setTitleId(datas.IdentID);
    setEditPop(true);
    setGovernmentFormValue({
      identype: datas.IdenType,
      identno: datas.IdentNo,
      issuedate: datas.IdentDate,
      issueby: datas.IdentBy,
    
    });
    console.log(datas.IdentID);
    setDate(Math.random());
  };

  // API to hit Notification Info
  // useEffect(() => {

  //   const dataForm = {
  //     ToleID: ToleID,
  //     tblOpt: "12",
  //     tblField: "ID NotificationID,user_id UserID,title Title,description Description,date Date,image Image,ac_btn_name AcBtn,ac_btn_url AcUrl",
  //     conditionField: "ID",
  //     conditionValues: titleID,
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "Y"
  //   }
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(JSON.stringify(dataForm), key, {
  //     iv: iv,
  //     mode: CryptoJS.mode.CBC,
  //     padding: CryptoJS.pad.Pkcs7,
  //   });
  //   var encryptedData = encrypted.toString();

  //   const dataToSend = {
  //     param: encryptedData,
  //   };
  //   const eventTitleList = async (dataToSend) => {

  //     const response = await fetch(
  //       `${URL}/toleAPI/api/selectdatawithcondition`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Signature: apisignature,
  //         },
  //         body: JSON.stringify(dataToSend),
  //       }
  //     );
  //     const getData = await response.json();
  //     return getData;
  //   };

  //   eventTitleList(dataToSend).then(function (result) {
  //     const code = CryptoJS.AES.decrypt(result.MESSAGE, key, {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     });
  //     const response = JSON.parse(code.toString(CryptoJS.enc.Utf8));
  //     console.log(response)
  //     if (response.STATUS_CODE === "0") {
  //       const data = response.dataList[0]

  //       setNoticeFormValue({
  //         people: data.UserID,
  //         member: data.UserID,
  //         title: data.Title,
  //         description: data.Description,
  //         actionBtn: data.AcBtn,
  //         actionUrl: data.AcUrl,
  //       });
  //       setImage(data.Image)
  //     }
  //   });
  // }, [titleID]);

  // useEffect(() => {
  //   easyList();
  // }, [titleID]);

  //API to hit notification list




  //API to update the government identity





  const [governmentList, setGovernmentList] = useState([]);

  useEffect(() => {
    governmentTitleList();
    console.log(governmentTitleList)
  }, [chooseMember, chooseOption,titleID]);

  // const noticeTitleList = () => {
  //   const listForm = {
  //     ToleID: ToleID,
  //     repoOpt: "recentnotice",
  //     repoID1: ToleID,
  //     repoID2: chooseOption === "member" ? chooseMember : "-1",
  //     // repoID2: "1",
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "N",
  //   };
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(
  //     JSON.stringify(listForm),
  //     key, //dataForm
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   );
  //   const dts = {
  //     param: encrypted.toString(),
  //   };
  //   console.log(dts)
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: {
  //       "Content-Type": "application/json",
  //       Signature: apisignature,
  //     },
  //     body: JSON.stringify(dts)
  //   };
  //   fetch(`${URL}/toleAPI/api/selectReport`, requestOptions)
  //     .then((result) => {
  //       result.json().then((resp) => {
  //         console.log(resp)
  //         if (resp.STATUS_CODE === "0") {
  //           setNoticeList(resp.dataList)
  //           setLoading(false)
  //         }
  //         else if (resp.STATUS_CODE === "1") {
  //           setNoticeList({})
  //           setLoading(false)
  //         }
  //       })
  //     })
  // };

  const governmentTitleList = () => {
    const params = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "S",
      MemID: "-1",
      IdentType: "-1",
      Type: "POST",
      FetchURL: `${urll}/admin/gov-ident`,
    };


    Fetchdata(params).then(function (result) {
      console.log(result);
      if (result.StatusCode === 200) {
        const postResult = result.govLst ? result.govLst : "";
        // setGovernmentFormValue({
        //   people: postResult[0].MemberID,
        //   member: "1",
        //   title: postResult[0].identtype,
        //   description: postResult[0].identno,
        //   actionBtn: postResult[0].identdate,
        //   actionUrl: postResult[0].identby,
        //   date: postResult[0].isactive,
        // });
       
        setLoading(false);
        setGovernmentList(postResult);
        console.log(governmentList)
      } else {
        setGovernmentList([]);
      }
    });
  };

  //API to hit Notification Status

  // const stateInitial = [];
  // const [newStat, setNewStat] = useState(stateInitial);

  // const deactivateNotice = async (NotificationID, IsActive) => {

  //   const formData = {
  //     ToleID: ToleID,
  //     userID: UserID.toString(),
  //     tblOpt: "12",
  //     tblField: "status",
  //     tblValues: IsActive.toString(),
  //     tblID: NotificationID.toString(),
  //     validationFields: "-",
  //     fieldforList: "-",
  //     orderbyField: "-",
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "Y"
  //   }
  //   if (IsActive === 1) {
  //     formData.tblValues = 0
  //   } else {
  //     formData.tblValues = 1
  //   }
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(
  //     JSON.stringify(formData),
  //     key, //dataForm
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   );

  //   const dts = {
  //     param: encrypted.toString(),
  //   };
  //   const response = await fetch(`${URL}/toleAPI/api/updatetbl`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Signature: apisignature,
  //     },
  //     body: JSON.stringify(dts), //dts
  //   });
  //   const tole = await response.json();

  //   if (tole.STATUS_CODE === "0") {
  //     noticeTitleList();
  //     let statsN = JSON.parse(JSON.stringify(newStat));
  //     let pitchStatus;

  //     if (formData.tblValues === 1) {
  //       pitchStatus = "Activated";

  //     } else if (formData.tblValues === 0) {
  //       pitchStatus = "Deactivated";
  //     }
  //     console.log(pitchStatus)
  //     setNewStat(statsN)
  //     setMsg({
  //       msg: `Notification ${pitchStatus} Successfully`,
  //       type: "alert alert-success",
  //     });
  //   } else {
  //     setMsg({
  //       msg: "Something went wrong.",
  //       type: "alert alert-danger",
  //     });
  //   }
  // }



  const stateInitial = [];
  const [newStat, setNewStat] = useState(stateInitial);
  const deactivateGovernment = (ID, IsActives) => {
    const editobj = {
      SliderID: ID,
      Status: IsActives,
    };
    const dataForm = {
      ToleID: ToleID,
      Flag: "ai",
      UserID: UserID,
      IdentID: editobj.SliderID,
      IsActive: editobj.Status,
    };
    if (IsActives === "A") {
      dataForm.IsActive = "I";
    } else {
      dataForm.IsActive = "A";
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/gov-ident`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          // console.log(resp)
          //eventListPop();
          governmentTitleList();
          let statsN = JSON.parse(JSON.stringify(newStat));
          let pitchStatus;

          if (dataForm.IsActive === "A") {
            pitchStatus = "Activated";
          } else if (dataForm.IsActive === "I") {
            pitchStatus = "Deactivated";
          }
          console.log(pitchStatus);
          setNewStat(statsN);
          setMsg({
            msg: resp.Message,
            type: "alert alert-success",
          });
        } else {
          setMsg({
            msg: resp.Message,
            type: "alert alert-danger",
          });
        }
      });
    });
  };


  //API to hit push notification
  // const NoticePushAll = () => {
  //   const listForm = {
  //     Title: "notice test all",
  //     Body: "notice test all",
  //     UserID: 0,
  //     Topic: ToleID,
  //   };

  //   const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(listForm),
  //   };
  //   fetch(`${URL}/toleAPI/api/push-notification-by-topic`, requestOptions).then(
  //     (result) => {
  //       result.json().then((resp) => {
  //         // console.log(resp);
  //       });
  //     }
  //   );
  // };


  //to delete
  
  
  const [Acdata, setACData] = useState();
  const [delPopup, setDelPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const handleDelete = (data) => {
    setACData(data);
    setDelPopup({
      show: true,
      data,
    });
  };


  const handleDeleteTrue = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "R",
      IdentID: Acdata,
    };
    // console.log(dataForm);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/gov-ident`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
            governmentTitleList();
          setMsg({
            msg: resp.Message,
            type: "alert alert-success",
          });
          setDelPopup({
            show: false,
            data: null,
          });
        } else {
          setMsg({
            msg: resp.Message,
            type: "alert alert-danger",
          });
        }
      });
    });
  };

  const handleDeleteFalse = () => {
    setDelPopup({
      show: false,
      data: null,
    });
  };


  const GovernmentPushindividual = () => {
    const listForm = {
      Title: "notice test",
      Body: "notice test",
      UserID: newValue,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(listForm),
    };
    fetch(
      `${URL}/toleAPI/api/push-notification-by-deviceID`,
      requestOptions
    ).then((result) => {
      result.json().then((resp) => {
        // console.log(resp);
      });
    });
  };

  return (
    <GovernmentContext.Provider
      value={{
        popup,
        setPopup,
        governmentFormValue,
        setGovernmentFormValue,
        governmentFormError,
        setGovernmentFormError,
        governmentSubmit,
        setGovernmentSubmit,
        loading,
        setLoading,
        editPop,
        setEditPop,
        handleEditGovernment,
        titleID,
        setTitleId,
        editIsSubmit,
        setEditIsSubmit,
        deactivateGovernment,
        reload,
        setReload,
        chooseOption,
        setChooseOption,
        chooseMember,
        setChooseMember,
        governmentList,
        closeChecked,
        setCloseChecked,
        newValue,
        setNewValue,
        governmentTitleList,
        pushGovernment,
        setPushGovernment,
        // NoticePushAll,
        GovernmentPushindividual,
        // easyList,
        date,
        setDate,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        delPopup,
        governmentList,
        handleDeleteTrue,
        handleDeleteFalse,
        handleDelete,
        delPopup,
        fetchdata,
        governmentvalue
      }}
    >
      {props.children}
    </GovernmentContext.Provider>
  );
}

export default GovernmentState;
