import React, { useContext, useEffect, useState, useRef } from "react";
import "../../css/Complain.css";
import Spinner from "../Spinner/Spinner";
import LedgerContext from "../contextFolder/LedgerContextFolder/LedgerContext";
import SidebarContext from "../sidebarcontext/SidebarContext";
import DeletePop from "./DeletePop";
import EditLedgerPopup from "./EditLedgerPopup";

const LedgerTable = ({ searchTerm }) => {
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ToleID = userinfo.ToleID;

  const {
    URL,
    mode,
    tableHeadBg,
    tableHeadWidthBg,
    tableDataBg,
    tableDataWidthBg,
    darkText,
  } = useContext(SidebarContext);

  const {
    handleDelete,
    loading,
    ledgerList,
    handleDeleteTrue,
    handleDeleteFalse,
    delPopup,
    handleEdit,
    viewPop,
    setViewPop,
    deactivateLedger,
  } = useContext(LedgerContext);

  const changeStatus = (LedgerID, IsActive) => {
    deactivateLedger(LedgerID, IsActive);
  };

  const checkStatus = (IsActive) => {
    if (IsActive === "A") {
      return "Deactivate";
    } else if (IsActive === "I") {
      return "Activate";
    }
  };


  const componentRef = useRef();
  const ref = React.createRef();
  const options = {
    orientation: "landscape",
    unit: "in",
    format: [12, 12],
  };

  if (ledgerList.length > 0) {
    return (
      <>
        <div className="outer-wrapper">
          <div
            className="table-wrapper"
            style={{
              overflowX: "scroll",
              overflowY: "scroll",
              margin: "3px",
              height: "301px",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <div>
                <div className="table__button__wrapper">
                  {/* <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText={<RiFileExcel2Fill style={{ color: mode === "light" ? "#555" : "#fff" }}/>}
                  />

                  <Pdf
                    targetRef={ref}
                    filename="code-example.pdf"
                    options={options}
                    x={0.5}
                    y={0.5}
                    scale={0.8}
                  >
                    {({ toPdf }) => (
                      <button onClick={toPdf}>
                        <BsFileEarmarkPdfFill style={{ color: mode === "light" ? "#555" : "#fff" }}/>
                      </button>
                    )}
                  </Pdf>

                  <ReactToPrint
                    trigger={() => {
                      return (
                        <button>
                          <AiFillPrinter style={{ color: mode === "light" ? "#555" : "#fff" }}/>
                        </button>
                      );
                    }}
                    content={() => componentRef.current}
                    documentTitle="new document"
                    pageStyle="print"
                  /> */}
                </div>
                <div ref={ref}>
                  <table ref={componentRef} className="table" id="table-to-xls">
                    <thead>
                      <tr className="tableHead">
                        <td style={tableHeadBg}>SN</td>
                        {/* <td style={tableHeadBg}>Date</td> */}
                        <td style={tableHeadBg}>Account No.</td>
                        <td style={tableHeadBg}>Account Name</td>
                        <td style={tableHeadBg}> Account Type</td>
                        <td style={tableHeadBg}> Status</td>
                        <td style={tableHeadBg}> Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {ledgerList
                        .filter((val) => {
                          if (searchTerm === "") {
                            return val;
                          } else if (
                            val.NepDate.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            ) ||
                            val.Particulars.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            )
                          ) {
                            return val;
                          }
                        })
                        .map((item, i) => (
                          <tr key={i}>
                            <td style={tableDataBg}>{i + 1}</td>
                            {/* <td style={tableDataBg}>{item.NepDate}</td> */}
                            <td style={tableDataBg}>{item.AccNo}</td>
                            <td style={tableDataBg}>{item.AccName}</td>
                            <td style={tableDataBg}>{item.AccType}</td>
                          
                            <td style={tableDataBg}>
                            <span
                              className="rejectspan badge"
                              onClick={() =>
                                changeStatus(item.AccID, item.IsActive)
                              }
                              style={darkText}
                            >
                             {checkStatus(item.IsActive)}
                            </span>

                          </td>
                             <td style={tableDataBg}>
                              <span
                                className="rejectspan badge"
                                onClick={() => handleEdit(item)}
                                style={darkText}
                              >
                                Edit
                              </span>
                              </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
        <EditLedgerPopup
          ledgerTrigger={viewPop}
          setLedgerTrigger={setViewPop}
        />

        {delPopup.show && (
          <DeletePop
            handleDeleteTrue={handleDeleteTrue}
            handleDeleteFalse={handleDeleteFalse}
          />
        )}
      </>
    );
  } else {
    return (
      <div className="outer-wrapper">
        <div
          className="table-wrapper"
          style={{
            overflowX: "scroll",
            overflowY: "scroll",
            margin: "3px",
            height: "300px",
          }}
        >
          <div>
            <div className="table__button__wrapper">
              {/* <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="table-to-xls"
                filename="tablexls"
                sheet="tablexls"
                buttonText={<RiFileExcel2Fill style={{ color: mode === "light" ? "#555" : "#fff" }}/>}
              />

              <Pdf
                targetRef={ref}
                filename="code-example.pdf"
                options={options}
                x={0.5}
                y={0.5}
                scale={0.8}
              >
                {({ toPdf }) => (
                  <button onClick={toPdf}>
                    <BsFileEarmarkPdfFill style={{ color: mode === "light" ? "#555" : "#fff" }}/>
                  </button>
                )}
              </Pdf>

              <ReactToPrint
                trigger={() => {
                  return (
                    <button>
                      <AiFillPrinter style={{ color: mode === "light" ? "#555" : "#fff" }}/>
                    </button>
                  );
                }}
                content={() => componentRef.current}
                documentTitle="new document"
                pageStyle="print"
              /> */}
            </div>
            <div ref={ref}>
              <table ref={componentRef} className="table" id="table-to-xls">
                <thead>
                  <tr className="tableHead">
                    <td style={tableHeadBg}>SN</td>
                    <td style={tableHeadBg}>Date</td>
                    <td style={tableHeadBg}>Receipt No.</td>
                    <td style={tableHeadBg}>Particular</td>
                    <td style={tableHeadBg}> Debit</td>
                    <td style={tableHeadBg}> credit</td>
                    <td style={tableHeadBg}> Action</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div className="text-center mt-4">
            <p style={darkText}> No data found</p>
          </div>
        </div>
      </div>
    );
  }
};

export default LedgerTable;
