import React, { useEffect, useState } from "react";
import SidebarContext from "./SidebarContext";
import CryptoJS from "crypto-js";
import UseHttp from "../hooks/Use-http";
function SidebarState(props) {
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const urll = process.env.REACT_APP_URL;
  const [menutoggle, setMenutoggle] = useState(false);
  const [logoutDropdownactive, setlogoutDropdownactive] = useState(false);
  const [alert, setAlert] = useState({
    fade: "fade-default",
    msg: "",
    type: "",
  });
  const [logoutdata, setLogout] = useState(false);
  const [resetPassword, setresetPassword] = useState(false);
  const [msg, setMsg] = useState({});
  const [reload, setReload] = useState();

  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ChhimekiID = userinfo.ChhimekiID;
  const UserID = userinfo.UserID;
  const URL = "https://esnepal.com";
  const { sendRequest } = UseHttp();
  // const urll = "http://hamichhimeki.com/api";

  // const [bg, setBg] = useState({
  //   background: "#fff",
  // });
  // const [color, setColor] = useState({
  //   background: "#fff",
  // });

  const [mode, setMode] = useState("light");
  const [themeText, setThemeText] = useState("Enable dark mode");

  const toggletheme = () => {
    if (mode === "light") {
      setMode("dark");
      setThemeText("Enable light mode");
    } else {
      setMode("light");
      setThemeText("Enable dark mode");
    }
  };

  const [popup, setPopup] = useState(false);
  const [titleList, setTitleList] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [nationList, setNationList] = useState([]);
  const [religion, setReligion] = useState([]);
  const [marital, setMarital] = useState([]);
  const [blood, setBlood] = useState([]);
  const [academic, setAcademic] = useState([]);
  const [relation, setRelation] = useState([]);
  const [sideMenu, setSideMenu] = useState(false);
  const [padding, setPadding] = useState(true);
  const [relationPopup, setRelationPopup] = useState(false);
  const [professionPopup2, setProfessionPopup2] = useState(false);
  const [academicPopup, setAcademicPopup] = useState(false);
  const [streetPopup, setStreetPopup] = useState(false);

  const titleFunction = () => {
    const listForm = {
      ComID: "es1",
      tblOpt: "20",
      tblField: "ID TitleID,title_name TitleName",
      DateTime: "2022/02/03 03:00 AM",
      Flag: "Y",
    };
    var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
    var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

    var encrypted = CryptoJS.AES.encrypt(JSON.stringify(listForm), key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    var encryptedData = encrypted.toString();

    const eventData = {
      param: encryptedData,
    };

    sendRequest(
      {
        url: `${URL}/toleAPI/api/selectalldata`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Signature: apisignature,
        },
        body: eventData,
      },

      (tasksObj) => {
        const code = CryptoJS.AES.decrypt(tasksObj.MESSAGE, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        const response = JSON.parse(code.toString(CryptoJS.enc.Utf8));

        if (tasksObj.STATUS_CODE === "0") {
          setTitleList(response.dataList);
        }
      }
    );
  };

  //API to get Gender
  const genderFunction = () => {
    const listForm = {
      ComID: "es1",
      tblOpt: "09",
      tblField: "ID GendID,gender_name GendName",
      DateTime: "2022/02/03 03:00 AM",
      Flag: "Y",
    };
    var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
    var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

    var encrypted = CryptoJS.AES.encrypt(JSON.stringify(listForm), key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    var encryptedData = encrypted.toString();

    const eventData = {
      param: encryptedData,
    };

    sendRequest(
      {
        url: `${URL}/toleAPI/api/selectalldata`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Signature: apisignature,
        },
        body: eventData,
      },

      (tasksObj) => {
        const code = CryptoJS.AES.decrypt(tasksObj.MESSAGE, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        const response = JSON.parse(code.toString(CryptoJS.enc.Utf8));

        if (tasksObj.STATUS_CODE === "0") {
          setGenderList(response.dataList);
        }
      }
    );
  };

  // useEffect(() => {
  //     const titleListShown = async (dataToSend) => {
  //         const response = await fetch(
  //             `${URL}/toleAPI/api/selectalldata`,
  //             {
  //                 method: "POST",
  //                 headers: {
  //                     "Content-Type": "application/json",
  //                     Signature: apisignature,
  //                 },
  //                 body: JSON.stringify(dataToSend),
  //             }
  //         );
  //         const getData = await response.json();
  //         return getData;
  //     };

  //     const listForm = {
  //         ComID: "es1",
  //         tblOpt: "09",
  //         tblField: "ID GendID,gender_name GendName",
  //         DateTime: "2022/02/03 03:00 AM",
  //         Flag: "Y"
  //     };
  //     var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //     var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //     var encrypted = CryptoJS.AES.encrypt(JSON.stringify(listForm), key, {
  //         iv: iv,
  //         mode: CryptoJS.mode.CBC,
  //         padding: CryptoJS.pad.Pkcs7,
  //     });
  //     var encryptedData = encrypted.toString();

  //     const eventData = {
  //         param: encryptedData,
  //     };

  //     titleListShown(eventData).then(function (result) {
  //         const code = CryptoJS.AES.decrypt(result.MESSAGE, key, {
  //             iv: iv,
  //             mode: CryptoJS.mode.CBC,
  //             padding: CryptoJS.pad.Pkcs7,
  //         });
  //         const response = JSON.parse(code.toString(CryptoJS.enc.Utf8));
  //         // console.log(response.dataList[0]);
  //         if (response.STATUS_CODE === "0") {
  //             setGenderList(response.dataList);
  //         }
  //     });
  // }, [genderList]);

  //API to get Nationality

  const nationalFunction = () => {
    const listForm = {
      ComID: "es1",
      tblOpt: "11",
      tblField: "ID NationalityID,nationality_name Nationality",
      DateTime: "2022/02/03 03:00 AM",
      Flag: "Y",
    };
    var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
    var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

    var encrypted = CryptoJS.AES.encrypt(JSON.stringify(listForm), key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    var encryptedData = encrypted.toString();

    const eventData = {
      param: encryptedData,
    };

    sendRequest(
      {
        url: `${URL}/toleAPI/api/selectalldata`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Signature: apisignature,
        },
        body: eventData,
      },

      (tasksObj) => {
        const code = CryptoJS.AES.decrypt(tasksObj.MESSAGE, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        const response = JSON.parse(code.toString(CryptoJS.enc.Utf8));

        if (tasksObj.STATUS_CODE === "0") {
          setNationList(response.dataList);
        }
      }
    );
  };

  //API to get Religion

  const religionFunction = () => {
    const listForm = {
      ComID: "es1",
      tblOpt: "18",
      tblField: "ID ReligionID,religion_name ReligionName",
      DateTime: "2022/02/03 03:00 AM",
      Flag: "Y",
    };
    var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
    var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

    var encrypted = CryptoJS.AES.encrypt(JSON.stringify(listForm), key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    var encryptedData = encrypted.toString();

    const eventData = {
      param: encryptedData,
    };

    sendRequest(
      {
        url: `${URL}/toleAPI/api/selectalldata`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Signature: apisignature,
        },
        body: eventData,
      },

      (tasksObj) => {
        const code = CryptoJS.AES.decrypt(tasksObj.MESSAGE, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        const response = JSON.parse(code.toString(CryptoJS.enc.Utf8));

        if (tasksObj.STATUS_CODE === "0") {
          setReligion(response.dataList);
        }
      }
    );
  };

  //API to get Marital Status

  const maritalFunction = () => {
    const listForm = {
      ComID: "es1",
      tblOpt: "10",
      tblField: "ID MaritalID,marital_status MaritalStatus",
      DateTime: "2022/02/03 03:00 AM",
      Flag: "Y",
    };
    var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
    var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

    var encrypted = CryptoJS.AES.encrypt(JSON.stringify(listForm), key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    var encryptedData = encrypted.toString();

    const eventData = {
      param: encryptedData,
    };

    sendRequest(
      {
        url: `${URL}/toleAPI/api/selectalldata`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Signature: apisignature,
        },
        body: eventData,
      },

      (tasksObj) => {
        const code = CryptoJS.AES.decrypt(tasksObj.MESSAGE, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        const response = JSON.parse(code.toString(CryptoJS.enc.Utf8));

        if (tasksObj.STATUS_CODE === "0") {
          setMarital(response.dataList);
        }
      }
    );
  };

  //API to get Blood Group

  const bloodFunction = () => {
    const listForm = {
      ComID: "es1",
      tblOpt: "02",
      tblField: "ID BloodID,blood_group BloodName",
      DateTime: "2022/02/03 03:00 AM",
      Flag: "Y",
    };
    var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
    var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

    var encrypted = CryptoJS.AES.encrypt(JSON.stringify(listForm), key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    var encryptedData = encrypted.toString();

    const eventData = {
      param: encryptedData,
    };

    sendRequest(
      {
        url: `${URL}/toleAPI/api/selectalldata`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Signature: apisignature,
        },
        body: eventData,
      },

      (tasksObj) => {
        const code = CryptoJS.AES.decrypt(tasksObj.MESSAGE, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        const response = JSON.parse(code.toString(CryptoJS.enc.Utf8));

        if (tasksObj.STATUS_CODE === "0") {
          setBlood(response.dataList);
        }
      }
    );
  };

  //API to get Relation

  const relationFunction = () => {
    const listForm = {
      ComID: "ES25",
      tblOpt: "17",
      tblField: "ID RelationID,relation_name Relation",
      DateTime: "2022/02/03 03:00 AM",
      Flag: "Y",
    };
    var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
    var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

    var encrypted = CryptoJS.AES.encrypt(JSON.stringify(listForm), key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    var encryptedData = encrypted.toString();

    const eventData = {
      param: encryptedData,
    };

    sendRequest(
      {
        url: `${URL}/toleAPI/api/selectalldata`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Signature: apisignature,
        },
        body: eventData,
      },

      (tasksObj) => {
        const code = CryptoJS.AES.decrypt(tasksObj.MESSAGE, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        const response = JSON.parse(code.toString(CryptoJS.enc.Utf8));

        if (tasksObj.STATUS_CODE === "0") {
          setRelation(response.dataList);
        }
      }
    );
  };

  //API to get Academic

  const academicFunction = () => {
    const listForm = {
      ComID: "es1",
      tblOpt: "01",
      tblField: "ID AcID,academic_name AcName",
      DateTime: "2022/02/03 03:00 AM",
      Flag: "Y",
    };
    var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
    var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

    var encrypted = CryptoJS.AES.encrypt(JSON.stringify(listForm), key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    var encryptedData = encrypted.toString();

    const eventData = {
      param: encryptedData,
    };

    sendRequest(
      {
        url: `${URL}/toleAPI/api/selectalldata`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Signature: apisignature,
        },
        body: eventData,
      },

      (tasksObj) => {
        const code = CryptoJS.AES.decrypt(tasksObj.MESSAGE, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });
        const response = JSON.parse(code.toString(CryptoJS.enc.Utf8));
        console.log(response);

        if (tasksObj.STATUS_CODE === "0") {
          setAcademic(response.dataList);
        }
      }
    );
  };

  //API to hit Chhimeki Info
  const [chhimekiList, setChhimekiList] = useState([]);

  // useEffect(() => {
  //   const listForm = {
  //     ChhimekiID: ChhimekiID,
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "Y",
  //   };
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(JSON.stringify(listForm), key, {
  //     iv: iv,
  //     mode: CryptoJS.mode.CBC,
  //     padding: CryptoJS.pad.Pkcs7,
  //   });
  //   var encryptedData = encrypted.toString();

  //   const eventData = {
  //     param: encryptedData,
  //   };

  //   sendRequest(
  //     {
  //       url: `${URL}/toleAPI/api/chhimeki-login`,
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Signature: apisignature,
  //       },
  //       body: eventData,
  //     },

  //     (tasksObj) => {
  //       const code = CryptoJS.AES.decrypt(tasksObj.MESSAGE, key, {
  //         iv: iv,
  //         mode: CryptoJS.mode.CBC,
  //         padding: CryptoJS.pad.Pkcs7,
  //       });
  //       const response = JSON.parse(code.toString(CryptoJS.enc.Utf8));

  //       if (tasksObj.STATUS_CODE === "0") {
  //         setChhimekiList(response.dataList);
  //       }
  //     }
  //   );
  // }, [chhimekiList]);

  // for dark mode & light mode
  const darkText = { color: mode === "light" ? "#000" : "#fff" };
  const darkBg = { background: mode === "light" ? "#fff" : "#040412" };
  const darkBg2 = { background: mode === "light" ? "#e6edf7" : "#0b0b45" };
  const sidePanelBg = { background: mode === "light" ? "#004aad" : "#0b0b45" };
  const mainBg = { background: mode === "light" ? "#f3f3f3" : "#040423" };
  const mainContent = { background: mode === "light" ? "#fff" : "#040423" };
  const tableHeadBg = {
    background: '#004aad',
    color: 'white',  // Make sure to use quotes around string values
  };
  const tableHeadWidthBg = {
    background: '#004aad',
    color: 'white',
    width: "220px",
  };
  const tableDataBg = {
    background: mode === "light" ? "#fff" : "#040423",
    color: mode === "light" ? "#000" : "#fff",
  };
  const tableDataWidthBg = {
    background: mode === "light" ? "#fff" : "#040423",
    color: mode === "light" ? "#000" : "#fff",
    width: "220px",
  };

  return (
    <SidebarContext.Provider
      value={{
        darkText,
        darkBg,
        darkBg2,
        sidePanelBg,
        mainBg,
        mainContent,
        tableHeadBg,
        tableHeadWidthBg,
        tableDataBg,
        tableDataWidthBg,
        menutoggle,
        setMenutoggle,
        padding,
        setPadding,
        mode,
        setMode,
        themeText,
        setThemeText,
        toggletheme,
        sideMenu,
        setSideMenu,
        logoutDropdownactive,
        setlogoutDropdownactive,
        alert,
        setAlert,
        logoutdata,
        setLogout,
        resetPassword,
        setresetPassword,
        msg,
        setMsg,
        chhimekiList,
        URL,
        titleList,
        setTitleList,
        genderList,
        setGenderList,
        nationList,
        setNationList,
        religion,
        setReligion,
        marital,
        setMarital,
        blood,
        setBlood,
        academic,
        setAcademic,
        relation,
        setRelation,
        bloodFunction,
        titleFunction,
        genderFunction,
        religionFunction,
        maritalFunction,
        nationalFunction,
        academicFunction,
        relationFunction,
        urll,
        relationPopup,
        setRelationPopup,
        professionPopup2,
        setProfessionPopup2,
        academicPopup,
        setAcademicPopup,
        popup,
        setPopup,
        reload,
        setReload,
        setStreetPopup,
        streetPopup,
      }}
    >
      {props.children}
    </SidebarContext.Provider>
  );
}

export default SidebarState;
