import React, { useContext, useState, useEffect } from "react";
import $ from "jquery";
import "../../css/imagePreview.css";
import CryptoJS from "crypto-js";
import SidebarContext from "../sidebarcontext/SidebarContext";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";
import AccountContext from "../contextFolder/AccountContextFolder/AccountContext";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import { Fetchdata } from "../hooks/getData";
import LedgerContext from "../contextFolder/LedgerContextFolder/LedgerContext";

export default function AccountForm(props) {
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ToleID = userinfo.ToleID;
  const UserID = userinfo.UserID;
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const { setMsg, urll, setPopup } = useContext(SidebarContext);

  const memcontext = useContext(MemberContext);
  const { memberDrop, def } = memcontext;


  const {
    accountFormError,
    setAccountFormError,
    accountSubmit,
    setAccountSubmit,
    accountFormValue,
    setAccountFormValue,
    accountvalue,
   
    closeChecked,
    setCloseChecked,
    reload,
    setReload,
    accountListShown,
    setPushNotice,
    pushNotice,
    AccountPush,
  } = useContext(AccountContext);
const {ledgerListShown,ledgerList}=useContext(LedgerContext


)
  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type == "checkbox" ? target.checked : target.value;
    setAccountFormValue({ ...accountFormValue, [name]: value });
  };
  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };
  const handlePushNotification = () => {
    setPushNotice(!pushNotice);
  };

  const handleDate = ({ bsDate }) => {
    setAccountFormValue({ ...accountFormValue, nepaliDate: bsDate });
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setAccountFormError(validate(accountFormValue));
    setAccountSubmit(true);
    console.log(accountFormValue);
  };

  useEffect(() => {
    if (Object.keys(accountFormError).length === 0 && accountSubmit) {
      fetchdata(accountFormValue);
      if (pushNotice === true) {
        AccountPush();
      }
      if (closeChecked === true) {
        $(".displayPopup").fadeOut(100);
        setPopup(false);
      }
      setAccountFormValue(accountvalue);
      setAccountSubmit(false);
      setPushNotice(false);
    }
  }, [accountFormError]);

  const closePopup = (e) => {
    e.preventDefault();
    $(".displayPopup").fadeOut(100);
    setPopup(false);
    setAccountFormValue(accountvalue);
    setAccountFormError({});
    setAccountSubmit(false);
    setPushNotice(false);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.member) {
      errors.member = "Required";
    }

    if (!values.nepaliDate) {
      errors.nepaliDate = "Required";
    }
    if (!values.particular) {
      errors.particular = "Required";
    }

    return errors;
  };

  //API to hit Account post
  const obj = {
    tole_id: ToleID,
    user_id: UserID,
    receipt_no: accountFormValue.receiptNumber,
    nepali_date: accountFormValue.nepaliDate,
    payment: accountFormValue.payment,
    receipt: accountFormValue.receipt,
    particulars: accountFormValue.particular,
    status: "1",
  };
  // const obj1 = Object.values(obj);
  // const obj2 = obj1.join("##");

  // const fetchdata = async () => {

  //     const dataForm = {
  //         ToleID: ToleID,
  //         userID: UserID.toString(),
  //         tblOpt: "30",
  //         tblField: "tole_id,user_id,receipt_no,nepali_date,payment,receipt,particulars,status",
  //         tblValues: obj2,
  //         validationFields: "receipt_no",
  //         fieldforList: "-",
  //         orderbyField: "-",
  //         DateTime: "2022/02/03 03:00 AM",
  //         Flag: "Y"
  //     }
  //     var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //     var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //     var encrypted = CryptoJS.AES.encrypt(
  //         JSON.stringify(dataForm),
  //         key, //dataForm
  //         {
  //             iv: iv,
  //             mode: CryptoJS.mode.CBC,
  //             padding: CryptoJS.pad.Pkcs7,
  //         }
  //     );

  //     const dts = {
  //         param: encrypted.toString(),
  //     };
  //     const response = await fetch(`${URL}/toleAPI/api/inserttbl`, {
  //         method: "POST",
  //         headers: {
  //             "Content-Type": "application/json",
  //             Signature: apisignature,
  //         },
  //         body: JSON.stringify(dts), //dts
  //     });
  //     const tole = await response.json();
  //     console.log(tole);

  //     if (tole.STATUS_CODE === "0") {
  //         accountListShown();
  //         setReload(!reload)
  //         setMsg({
  //             msg: tole.MESSAGE,
  //             type: "alert alert-success",
  //         });
  //     } else if (tole.STATUS_CODE === "1") {
  //         setMsg({
  //             msg: tole.MESSAGE,
  //             type: "alert alert-danger",
  //         });
  //     }

  // };
  const fetchdata = () => {
    const dataForm = {
      ToleID: ToleID,
      Flag: "I",
      UserID: UserID,
      MemID: accountFormValue.member,
      AccNo: accountFormValue.receiptNumber,
      AccType: "D",
      LedgerID:accountFormValue.accname,
      NepDate: accountFormValue.nepaliDate,
      Debit: accountFormValue.payment,
      Credit: accountFormValue.receipt,
      Particulars: accountFormValue.particular,
      Notify: pushNotice ? "y" : "n",
      FetchURL: `${urll}/admin/simp-acc`,
      Type: "POST",
    };
    console.log(dataForm);
    Fetchdata(dataForm).then(function (result) {
      console.log(result);
      if (result.StatusCode === 200) {
        accountListShown();
        setMsg({
          msg: result.Message,
          type: "alert alert-success",
        });
      } else {
        setMsg({
          msg: result.Message,
          type: "alert alert-danger",
        });
      }
    });
  };

  return (
    <>
      <div className="container-fluid basicform">
        <form>
          <div className="form-row">
            <div
              className="form-group col-md-6"
              style={{ marginBottom: "-0.8px" }}
            >
              <label className="form-label" htmlFor="member">
                Member <sup className="sup-col">*</sup>
              </label>

              <select
                id="member"
                name="member"
                onChange={handleChange}
                value={accountFormValue.member}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="none" selected>
                  Select Member
                </option>
                {memberDrop.map((item) => (
                  <option key={item.UserID} value={item.UserID}>
                    {item.FullName}
                  </option>
                ))}
              </select>
              <p className="errormsg ">{accountFormError.member}</p>
            </div>

            <div
              className="form-group col-md-6"
              style={{ marginBottom: "-0.8px" }}
            >
              <label className="form-label" htmlFor="accname">
                Select Account<sup className="sup-col">*</sup>
              </label>

              <select
                id="accname"
                name="accname"
                onChange={handleChange}
                value={accountFormValue.accname}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
              >
                <option value="none" selected>
                  Select Account
                </option>
                {ledgerList.map((item) => (
                  <option key={item.AccID} value={item.AccID}>
                    {item.AccName}
                  </option>
                ))}
              </select>
              <p className="errormsg ">{accountFormError.accname}</p>
            </div>




          </div>

          <div className="form-row">
            <div
              className="form-group col-md-6"
              style={{ marginBottom: "-0.8px" }}
            >
              <label
                htmlFor="nepaliDate"
                className="form-label"
                placeholder="description"
              >
                Nepali Date<sup className="sup-col">*</sup>
              </label>
              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="YYYY/MM/DD"
                theme="default"
                language="en"
                values={accountFormValue.nepaliDate}
                onChange={handleDate}
                defaultDate="YYYY/MM/DD"
              />
              <p className="errormsg ">{accountFormError.nepaliDate}</p>
            </div>

            <div
              className="form-group col-md-6  "
              style={{ marginBottom: "-0.8px" }}
            >
              <label className="form-label" htmlFor="receiptNumber">
                Account Number
              </label>
              <input
                type="text"
                className="form-control form-control-sm mb-1"
                onChange={handleChange}
                value={accountFormValue.receiptNumber}
                id="receiptNumber"
                name="receiptNumber"
              />
            </div>
          </div>

          <div className="form-row">
            <div
              className="form-group col-md-6"
              style={{ marginBottom: "-0.8px" }}
            >
              <label htmlFor="payment" className="form-label">
                Payment(DR)
              </label>
              <input
                type="text"
                className="form-control form-control-sm mb-1"
                name="payment"
                value={accountFormValue.payment}
                onChange={handleChange}
                id="payment"
              ></input>
            </div>

            <div
              className="form-group col-md-6 "
              style={{ marginBottom: "-0.8px" }}
            >
              <label className="form-label" htmlFor="receipt">
                Receipt(CR)
              </label>
              <input
                type="text"
                className="form-control form-control-sm mb-1"
                onChange={handleChange}
                value={accountFormValue.receipt}
                id="receipt"
                name="receipt"
              />
            </div>
          </div>

          <div className="form-row">
            <div
              className="form-group col-md-12  "
              style={{ marginTop: "10px" }}
            >
              <label className="form-label" htmlFor="particular">
                Particular <sup className="sup-col">*</sup>
              </label>
              <input
                type="text"
                className="form-control form-control-sm mb-1"
                onChange={handleChange}
                value={accountFormValue.particular}
                id="particular"
                name="particular"
              />
              <p className="errormsg ">{accountFormError.particular}</p>
            </div>
          </div>
        </form>
      </div>
      <div className="col-lg-12 managementFormFooter ">
        <div
          class="form-check notification-form-check"
          style={{ display: "flex" }}
        >
          <div>
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              name="isChecked"
              onChange={handlePushNotification}
              checked={pushNotice}
            />
            <label class="form-check-label" for="exampleCheck1">
              Notify User
            </label>
          </div>

          <div className="checkbox-close">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck2"
              name="closeChecked"
              onChange={handleOnChange}
              checked={closeChecked}
            />
            <label class="form-check-label" for="exampleCheck2">
              Exit after submit
            </label>
          </div>
        </div>
        <div className="notification-form-button">
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formSubmit}
          >
            Submit
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
