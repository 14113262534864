import React, { useContext, useEffect, useState } from "react";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import LedgerContext from "./LedgerContext";
import UseHttp from '../../hooks/Use-http';
import { Fetchdata } from "../../hooks/getData";
import $ from "jquery";


function LedgerState(props) {

    const userinfo = JSON.parse(localStorage.getItem("userInfo"));
    const ToleID = userinfo.ToleID;
    const UserID = userinfo.UserID
    const apisignature = process.env.REACT_APP_SIGNATURE;
    const { setMsg, urll } = useContext(SidebarContext);
    const { sendRequest } = UseHttp();
    const [loading, setLoading] = useState(true);


    const [popup, setPopup] = useState(false);
    const [ledgerFormError, setLedgerFormError] = useState({});
    const [ledgerSubmit, setLedgerSubmit] = useState(false);
    const [reload, setReload] = useState(false);
    const [perID, setPerId] = useState(null);
    const [viewPop, setViewPop] = useState(false);

    const [chooseOption, setChooseOption] = useState("-1")

    const ledgervalue = {
        member: "",
        nepaliDate: "",
        receiptNumber: "",
        acctype:"",
        name:"",
        isChecked: false,
    };
    const [ledgerFormValue, setLedgerFormValue] = useState(ledgervalue);
    const [closeChecked, setCloseChecked] = useState(false)
    const [pushNotice, setPushNotice] = useState(false);


    //date value for account
    const datevalue = {
        fromDate: "",
        toDate: "",
    };
    const [dateFormValue, setDateFormValue] = useState(datevalue);

    //date value for account ends

    //date value for account summary
    const sumdatevalue = {
        fromDate: "",
        toDate: "",
    };
    const [sumDateFormValue, setSumDateFormValue] = useState(sumdatevalue);
    //date value for account summary ends

    //API to get account List 


    const [ledgerList, setLedgerList] = useState([]);

    useEffect(() => {
        ledgerListShown();
    },[])
    // }, [chooseOption, dateFormValue.fromDate || dateFormValue.toDate, dateFormValue.toDate, dateFormValue.fromDate])


    // const accountListShown = () => {
    //     const listForm = {
    //         ToleID: ToleID,
    //         repoOpt: "acindividualweb",
    //         repoID1: chooseOption,
    //         dtFrom: dateFormValue.fromDate,
    //         dtUpto: dateFormValue.toDate,
    //         DateTime: "2022/02/03 03:00 AM",
    //         Flag: "N"

    //     };
    //     var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
    //     var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

    //     var encrypted = CryptoJS.AES.encrypt(
    //         JSON.stringify(listForm),
    //         key,
    //         {
    //             iv: iv,
    //             mode: CryptoJS.mode.CBC,
    //             padding: CryptoJS.pad.Pkcs7,
    //         }
    //     );
    //     const dts = {
    //         param: encrypted.toString(),
    //     };
    //     console.log(dts)
    //     const requestOptions = {
    //         method: 'POST',
    //         headers: {
    //             "Content-Type": "application/json",
    //             Signature: apisignature,
    //         },
    //         body: JSON.stringify(dts)
    //     };
    //     fetch(`${URL}/toleAPI/api/selectReport`, requestOptions)
    //         .then((result) => {
    //             result.json().then((resp) => {
    //                 console.log(resp)
    //                 if (resp.STATUS_CODE === "0") {
    //                     setAccountList(resp.dataList);
    //                     setLoading(false)
    //                 }
    //             })
    //         })
    // };

    const ledgerListShown = () => {
        const params = {
            ToleID: ToleID,
            Flag: "S",
            UserID: UserID,
            MemID: -1,  //chooseOption
            // FromDate: dateFormValue.fromDate,
            // ToDate: dateFormValue.toDate,
            AccType: "-1",
            Type: "POST",
            FetchURL: `${urll}/admin/acc-ledger`,
        };

        Fetchdata(params).then(function (result) {
            // console.log(result)
            if (result.StatusCode === 200) {
                const postResult = result.AccountLedLst ? result.AccountLedLst : "";
                setLoading(false);
                setLedgerList(postResult);

            } else {
                setLedgerList([]);
            }
        });
    }

    //To edit the data
    const [perEditSubmit, setPerEditSubmit] = useState(false);




    const [delPopup, setDelPopup] = useState({
        show: false, // initial values set to false and null
        data: null,
    });
    const [todos, setToDos] = useState([]);

    const [Acdata, setACData] = useState()

    // To delete the Account List

    const handleDelete = (data) => {

        setACData(data)

        setDelPopup({
            show: true,
            data,
        });

    };


    const handleEdit = (data) => {
      setPerId(data.AccID);
      setCloseChecked(false);
      $(".viewHelplinePop").fadeIn(100);
      setViewPop(true);
      setLedgerFormValue({
        name: data.AccName,
        acctype: data.AccType,
        // member:data.MemID,
        receiptNumber: data.AccNo,
      });
      //console.log(perID);
    };


    const editobj = {
      names: ledgerFormValue.name,
      acctypes: ledgerFormValue.acctype,
      numbers: ledgerFormValue.receiptNumber,
      // member:data.MemID,
      LedgerID: perID,
    };
    //console.log(editobj);
    const editdata = () => {
      const dataForm = {
        ToleID: ToleID,
        Flag: "U",
        UserID: UserID,
        AccID: editobj.LedgerID,
        AccNo: editobj.numbers,
        AccType: editobj.acctypes,
        AccName: editobj.names, // 'names' corrected to 'name'
        FetchURL: `${urll}/admin/acc-ledger`,
        Type: "POST",
      };
      
      // Log the dataForm being sent
      console.log("Data being sent: ", dataForm);
    
      Fetchdata(dataForm)
        .then((result) => {
          // Log the successful result
          console.log("API response: ", result);
          
          if (result.StatusCode === 200) {
            $(".viewHelplinePop").fadeOut(100);
            ledgerListShown();
            setMsg({
              msg: result.Message,
              type: "alert alert-success",
            });
          } else {
            setMsg({
              msg: result.Message,
              type: "alert alert-danger",
            });
            // Log the error message when StatusCode is not 200
            console.error("Error response: ", result);
          }
        })
        .catch((error) => {
          // Catch any network or server errors
          console.error("Request failed: ", error);
        });
    };
    

    // This will perform the deletion and hide the Confirmation Box

    // const handleDeleteTrue = async () => {

    //     const dataForm = {
    //         ToleID: ToleID,
    //         userID: UserID.toString(),
    //         tblOpt: "30",
    //         tblField: "status",
    //         tblValues: "0",
    //         tblID: Acdata,
    //         validationFields: "-",
    //         fieldforList: "-",
    //         orderbyField: "-",
    //         DateTime: "2022/02/03 03:00 AM",
    //         Flag: "Y"
    //     }
    //     var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
    //     var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

    //     var encrypted = CryptoJS.AES.encrypt(
    //         JSON.stringify(dataForm),
    //         key, //dataForm
    //         {
    //             iv: iv,
    //             mode: CryptoJS.mode.CBC,
    //             padding: CryptoJS.pad.Pkcs7,
    //         }
    //     );

    //     const dts = {
    //         param: encrypted.toString(),
    //     };
    //     const response = await fetch(`${URL}/toleAPI/api/updatetbl`, {
    //         method: "POST",
    //         headers: {
    //             "Content-Type": "application/json",
    //             Signature: apisignature,
    //         },
    //         body: JSON.stringify(dts), //dts
    //     });
    //     const tole = await response.json();
    //     console.log(tole);

    //     if (tole.STATUS_CODE === "0") {
    //         setReload(!reload)
    //         accountListShown();
    //         setMsg({
    //             msg: tole.MESSAGE,
    //             type: "alert alert-success",
    //         });
    //     } else if (tole.STATUS_CODE === "1") {
    //         setMsg({
    //             msg: tole.MESSAGE,
    //             type: "alert alert-danger",
    //         });
    //     }
    //     setDelPopup({
    //         show: false,
    //         data: null,

    //     });
    // };

    const handleDeleteTrue = () => {
        const dataForm = {
            ToleID: ToleID,
            UserID: UserID,
            Flag: "R",
            AccID: Acdata,
            // Status: "0",
            Type: "POST",
            FetchURL: `${urll}/admin/acc-ledger`,
        }
        Fetchdata(dataForm).then(function (result) {
            // console.log(result);
            if (result.StatusCode === 200) {
                ledgerListShown();
                setMsg({
                    msg: result.Message,
                    type: "alert alert-success",
                });
            } else {
                setMsg({
                    msg: result.Message,
                    type: "alert alert-danger",
                });
            }
        });
    }

    // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

    const handleDeleteFalse = () => {
        setDelPopup({
            show: false,
            data: null,
        });
    };


    //API to hit push notification


    // const LedgerPush = () => {
    //     const listForm = {
    //         Title: "ledger test",
    //         Body: "ledger test",
    //         UserID: 0,
    //         Topic: ToleID,
    //     }

    //     const requestOptions = {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify(listForm)
    //     };
    //     fetch(`${URL}/toleAPI/api/push-notification-by-topic`, requestOptions)
    //         .then((result) => {
    //             result.json().then((resp) => {
    //                 // console.log(resp)
    //             })
    //         })
    // }



    //to activate/deactivate ledger
    const stateInitial = [];
  const [newStat, setNewStat] = useState(stateInitial);
  const deactivateLedger = (ID, IsActives) => {
    const editobj = {
      SliderID: ID,
      Status: IsActives,
    };
    const dataForm = {
      ToleID: ToleID,
      Flag: "AD",
      UserID: UserID,
      AccID: editobj.SliderID,
      IsActive: editobj.Status,
    };
    if (IsActives === "A") {
      dataForm.IsActive = "D";
    } else {
      dataForm.IsActive = "A";
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/acc-ledger`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          // console.log(resp)
          //eventListPop();
          ledgerListShown();
          let statsN = JSON.parse(JSON.stringify(newStat));
          let pitchStatus;

          if (dataForm.IsActive === "A") {
            pitchStatus = "Activated";
          } else if (dataForm.IsActive === "D") {
            pitchStatus = "Deactivated";
          }
          console.log(pitchStatus);
          setNewStat(statsN);
          setMsg({
            msg: resp.Message,
            type: "alert alert-success",
          });
        } else {
          setMsg({
            msg: resp.Message,
            type: "alert alert-danger",
          });
        }
      });
    });
  };


    return (
        <LedgerContext.Provider
            value={{
                popup,
                setPopup,editdata, viewPop,setPerEditSubmit,perEditSubmit,deactivateLedger,setViewPop,ledgerFormError, handleEdit, setLedgerFormError, ledgerSubmit, setLedgerSubmit, ledgerFormValue, setLedgerFormValue, ledgervalue, closeChecked, setCloseChecked, reload, setReload, chooseOption, setChooseOption, dateFormValue, setDateFormValue, sumDateFormValue, setSumDateFormValue, sumdatevalue, handleDelete, loading, ledgerList, ledgerListShown, pushNotice, setPushNotice, handleDeleteTrue, handleDeleteFalse, delPopup
            }}
        >
            {props.children}
        </LedgerContext.Provider>
    );
}

export default LedgerState;
