import React, { useState, useEffect, useContext } from "react";
import MemberContext from "./MemberContext";
import CryptoJS from "crypto-js";
import SidebarContext from "../../sidebarcontext/SidebarContext";
import UseHttp from "../../hooks/Use-http";
import $ from "jquery";
import { Fetchdata } from "../../hooks/getData";
import DashboardContext from "../DashboardContextFolder/DashboardContext";

function MemberState(props) {
  const apisignature = process.env.REACT_APP_SIGNATURE;
  const userinfo = JSON.parse(localStorage.getItem("userInfo"));
  const ToleID = userinfo.ToleID;
  const UserName = userinfo.UserName;
  const UserID = userinfo.UserID;

  const { setShowMember } = useContext(DashboardContext);
  const {
    setMsg,
    URL,
    bloodFunction,
    titleFunction,
    genderFunction,
    religionFunction,
    maritalFunction,
    nationalFunction,
    urll,
  } = useContext(SidebarContext);
  // const{familyList}=useContext(FamilyContext)
  const [closeChecked, setCloseChecked] = useState(false);
  const [activeTab, setActiveTab] = useState({
    tab1: true,
    tab2: false,
    tab3: false,
    tab4: false,
    tab5: false,
    tab6: false,
  });
  const [image, setImage] = useState("");
  const [isUploaded, setIsUploaded] = useState(false);
  const [typeFile, setTypeFile] = useState("");

  const [popup, setPopup] = useState(false);
  const [viewPop, setViewPop] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [memberFormErrors, setMemberFormErrors] = useState({});

  const [permanentIsSubmit, setPermanentIsSubmit] = useState(false);
  const [permanentFormError, setPermanentFormError] = useState({});

  const [temporaryIsSubmit, setTemporaryIsSubmit] = useState(false);
  const [temporaryFormError, setTemporaryFormError] = useState({});

  // const [familySubmit, setFamilySubmit] = useState(false);
  // const [familyFormError, setFamilyFormError] = useState({});

  const [finalSubmit, setFinalSubmit] = useState(false);
  const [finalFormError, setFinalFormError] = useState({});
  const [reload, setReload] = useState(false);
  const [re, setRe] = useState();
  //useState for Edit

  const [perEditSubmit, setPerEditSubmit] = useState(false);
  const [tempSubmit, setTempSubmit] = useState(false);
  const [perSubmit, setPerSubmit] = useState(false);
  const [otherSubmit, setOtherSubmit] = useState(false);

  const [familyObject, setFamilyObject] = useState();

  // to get family data
  const getFamilyData = sessionStorage.getItem("familyData");
  const getFamilyValue = JSON.parse(getFamilyData);
  // console.log(getFamilyValue);

  // to get profession data
  const getProfessionData = sessionStorage.getItem("professionData");
  const getProfessionValue = JSON.parse(getProfessionData);
  // console.log(getProfessionValue);

  // family data
  const formValue1 = {
    FullName: "",
    CurrentAddress: "",
    RelationID: "",
    RelationName: "",
    Gender: "",
    Phone: "",
    DOB: "",
    Email: "",
    Profession: "",
    Academic: "",
    BloodGroup: "",
    Nationality: "",
    Religion: "",
    MaritalStatus: "",
    PAN: "",
    CitizenNo: "",
    IssuedBy: "",
    DateOfIssue: "",
    VoterID: "",
    NationalID: "",
    Passport: "",
    perm_address:"",
    temp_address:""
  };
  const [familyFormValue1, setFamilyFormValue1] = useState(formValue1);
  const [isUploaded1, setIsUploaded1] = useState(false);
  const [typeFile1, setTypeFile1] = useState("");
  const [closeChecked1, setCloseChecked1] = useState(false);
  const [familySubmit1, setFamilySubmit1] = useState(false);
  const [familyFormError1, setFamilyFormError1] = useState({});
  const [familyList1, setFamilyList1] = useState([]);
  const [famPopup1, setFamPopup1] = useState(false);
  const [image1, setImage1] = useState("");

  // profession data
  const professionvalue2 = {
    ProfessionID: "",
    Position: "",
    OrgName: "",
    Address: "",
    isChecked: false,
  };

  const [professionFormValue2, setProfessionFormValue2] =
    useState(professionvalue2);
  const [closeChecked2, setCloseChecked2] = useState(false);
  const [popup2, setPopup2] = useState(false);
  const [professionFormError2, setProfessionFormError2] = useState({});
  const [professionSubmit2, setProfessionSubmit2] = useState(false);
  const [proList2, setProList2] = useState([]);

  // member data
  const membervalue = {
    relation: "",
    fName: "",
    mName: "",
    lName: "",
    title: "",
    mAddress: "",
    uName: "",
    gender: "",
    nationality: "",
    religion: "",
    marriage: "",
    blood: "",
    bsdate: "",
    addate: "",
    qualification: "",
    father: "",
    mother: "",
    grandfather: "",
    provience: "",
    zone: "",
    district: "",
    municipality: "",
    ward: "",
    address: "",
    street: "",
    house: 0,
    pan: "",
    latitude: "",
    longitude: "",
    tprovience: "",
    tzone: "",
    tdistrict: "",
    tmunicipality: "",
    tward: "",
    taddress: "",
    tstreet: "",
    thouse: 0,
    tpan: "",
    tlatitude: "",
    tlongitude: "",
    citizenship: "",
    cIssued: "",
    cDate: "",
    passport: "",
    pIssued: "",
    pDate: "",
    voter: "",
    vIssued: "",
    vDate: "",
    license: "",
    lIssued: "",
    lDate: "",
    electricity: "",
    eIssued: "",
    eDate: "",
    phHome: "",
    phOffice: "",
  };

  const [loading, setLoading] = useState(true);

  const [memberFormValue, setMemberFormValue] = useState(membervalue);

  // const memberListShown = () => {
  //   const listForm = {
  //     ToleID: ToleID,
  //     repoOpt: "memberlistweb",
  //     repoID1: ToleID,
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "N",
  //   };
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(
  //     JSON.stringify(listForm),
  //     key,
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     }
  //   );
  //   const dts = {
  //     param: encrypted.toString(),
  //   };
  //   console.log(dts)
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: {
  //       "Content-Type": "application/json",
  //       Signature: apisignature,
  //     },
  //     body: JSON.stringify(dts)
  //   };
  //   fetch(`${URL}/toleAPI/api/selectReport`, requestOptions)
  //     .then((result) => {
  //       result.json().then((resp) => {
  //         console.log(resp)
  //         if (resp.STATUS_CODE === "0") {
  //           setMemberList(resp.dataList)
  //           setLoading(false)
  //         }
  //       })
  //     })
  // };


//for ref value dropdown of member

  const [memberDrop, setMemberDrop] = useState([]);
  const [professionDrop, setProfessionDrop] = useState([]);
  useEffect(() => {
    professionDropShown();
  }, [reload]);
  useEffect(() => {
    memberDropShown();
  }, [reload]);

  const memberDropShown = () => {
    const params = {
      ToleID: ToleID,
      Flag: "M",
      UserID: "-1",
      Type: "POST",
      FetchURL: `${urll}/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      // console.log(result);
      if (result.StatusCode === 200) {
        memberListShown();
        const postResult = result.RefLst ? result.RefLst : "";
        setLoading(false);
        setMemberDrop(postResult);
      } else {
        setMemberDrop([]);
      }
    });
  };


  const professionDropShown = () => {
    const params = {
      ToleID: ToleID,
      Flag: "SPP",
      UserID: "1",
      Type: "POST",
      FetchURL: `${urll}/admin/ref-value`,
    };

    Fetchdata(params).then(function (result) {
      // console.log(result);
      if (result.StatusCode === 200) {
        memberListShown();
        const postResult = result.RefLst ? result.RefLst : "";
        setLoading(false);
        setProfessionDrop(postResult);
      } else {
        setProfessionDrop([]);
      }
    });
  };

  const [memberList, setMemberList] = useState([]);
  useEffect(() => {
    memberTypeShown();
  }, [reload]);

  const memberTypeShown = () => {
    const params = {
      ToleID: ToleID,
      Flag: "S",
      UserID: "1",
      Type: "POST",
      FetchURL: `${urll}/admin/user`,
    };

    Fetchdata(params).then(function (result) {
      // console.log(result);
      if (result.StatusCode === 200) {
        memberListShown();
        const postResult = result.userLst ? result.userLst : "";
        setLoading(false);
        setMemberList(postResult);
      } else {
        setMemberList([]);
      }
    });
  };

  const [memberType, setMemberType] = useState([]);
  useEffect(() => {
    memberListShown();
  }, [reload]);

  const memberListShown = () => {
    const params = {
      ToleID: ToleID,
      Flag: "S",
      UserID: "1",
      Type: "POST",
      FetchURL: `${urll}/admin/user`,
    };

    Fetchdata(params).then(function (result) {
      // console.log(result);
      if (result.StatusCode === 200) {
        const postResult = result.UserLst ? result.UserLst : "";
        setLoading(false);
        setMemberType(postResult);
      } else {
        setMemberType([]);
      }
    });
  };

  const [currentStep, setStep] = useState(1);

  const obj = {
    tole_id: ToleID,
    user_name: memberFormValue.uName,
    user_type: 1,
    mail_address: memberFormValue.mAddress,
    user_photo: "-",
    relation_id: memberFormValue.relation,
    first_name: memberFormValue.fName,
    middle_name: memberFormValue.mName,
    last_name: memberFormValue.lName,
    title_id: memberFormValue.title,
    gender_id: memberFormValue.gender,
    nationality_id: memberFormValue.nationality,
    religion_id: memberFormValue.religion,
    material_id: memberFormValue.marriage,
    blood_id: memberFormValue.blood,
    dob_bs: memberFormValue.bsdate,
    dob_ad: memberFormValue.addate,
    qualification: memberFormValue.qualification,
    father_name: memberFormValue.father,
    mother_name: memberFormValue.mother,
    grand_father: memberFormValue.grandfather,
    perm_address: memberFormValue.address,
    perm_province_id: memberFormValue.provience,
    perm_zone_id: memberFormValue.zone,
    perm_district_id: memberFormValue.district,
    perm_vdc_id: memberFormValue.municipality,
    perm_ward_id: memberFormValue.ward,
    perm_pan: memberFormValue.pan,
    perm_house_no: memberFormValue.house,
    perm_tole: memberFormValue.street,
    perm_latitude: memberFormValue.latitude,
    perm_longitude: memberFormValue.longitude,
    temp_address: memberFormValue.taddress,
    temp_province_id: memberFormValue.tprovience,
    temp_zone_id: memberFormValue.tzone,
    temp_district_id: memberFormValue.tdistrict,
    temp_vdc_id: memberFormValue.tmunicipality,
    temp_ward_id: memberFormValue.tward,
    temp_pan: memberFormValue.tpan,
    temp_house_no: memberFormValue.thouse,
    temp_tole: memberFormValue.tstreet,
    temp_latitude: memberFormValue.tlatitude,
    temp_longitude: memberFormValue.tlongitude,
    // FamilyObj: familyList,

    // Full_name: familyFormValue1.FullName,
    // Current_address: familyFormValue1.CurrentAddress,
    // Relation_id: familyFormValue1.RelationID,
    // Gender_id: familyFormValue1.Gender,
    // Phone: familyFormValue1.Phone,
    // DOB: familyFormValue1.DOB,
    // Email: familyFormValue1.Email,
    // Profession_id: familyFormValue1.Profession,
    // Academic_id: familyFormValue1.Academic,
    // Blood_id: familyFormValue1.BloodGroup,
    // Nationality_id: familyFormValue1.Nationality,
    // Religion_id: familyFormValue1.Religion,
    // Marital_id: familyFormValue1.MaritalStatus,
    // Citizenship_no: familyFormValue1.CitizenNo,
    // Citizen_issue_by: familyFormValue1.IssuedBy,
    // Citizenship_issue_date: familyFormValue1.DateOfIssue,
    // Voter_id: familyFormValue1.VoterID,
    // National_id: familyFormValue1.NationalID,
    // Pan_no: familyFormValue1.PAN,
    // Passport: familyFormValue1.Passport,

    citizenship_no: memberFormValue.citizenship,
    citizen_issue_by: memberFormValue.cIssued,
    citizenship_issue_date: memberFormValue.cDate,
    passport_no: memberFormValue.passport,
    passport_issue_by: memberFormValue.pIssued,
    passport: memberFormValue.passport,
    voter_id: memberFormValue.voter,
    voter_issue_by: memberFormValue.vIssued,
    voter_issue_date: memberFormValue.vDate,
    license_no: memberFormValue.license,
    license_issue_by: memberFormValue.lIssued,
    license_issue_date: memberFormValue.lDate,
    electric_no: memberFormValue.electricity,
    electric_issue_by: memberFormValue.eIssued,
    electric_issue_date: memberFormValue.eDate,
    phone_Home: memberFormValue.phHome,
 
    status: 1,
  };
  // const obj1 = Object.values(obj)
  // const obj2 = obj1.join("##");

  // // console.log(obj2);

  // const fetchdata = async (dataToSend) => {

  //   const dataForm = {
  //     ToleID: ToleID,
  //     userID: UserID.toString(),
  //     tblOpt: "22",
  //     tblField: "tole_id,user_name,user_type,mail_address,user_photo,first_name,middle_name,last_name,title_id,gender_id,nationality_id,religion_id,material_id,blood_id,dob_bs,dob_ad,qualification,father_name,mother_name,grand_father,perm_address,perm_province_id,perm_zone_id,perm_district_id,perm_vdc_id,perm_ward_id,perm_pan,perm_house_no,perm_tole,perm_latitude,perm_longitude,temp_address,temp_province_id,temp_zone_id,temp_district_id,temp_vdc_id,temp_ward_id,temp_pan,temp_house_no,temp_tole,temp_latitude,temp_longitude,citizenship_no,citizen_issue_by,citizenship_issue_date,passport_no,passport_issue_by,passport_issue_date,voter_id,voter_issue_by,voter_issue_date,license_no,license_issue_by,license_issue_date,electric_no,electric_issue_by,electric_issue_date,status",
  //     tblValues: obj2,
  //     // tblValues: "ES25##4##1##1##1##Apples##1##Ball##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1##1",
  //     validationFields: "user_name",
  //     fieldforList: "-",
  //     orderbyField: "-",
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "Y"
  //   }
  //   var key = CryptoJS.enc.Utf8.parse('D89035A6634F4C4786B947518F17A18A');
  //   var iv = CryptoJS.enc.Utf8.parse('EasyS0ftS0ftware');

  //   var encrypted = CryptoJS.AES.encrypt(JSON.stringify(dataForm), key, //dataForm
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7
  //     });

  //   console.log("encplogin", encrypted.toString())
  //   const dts = {
  //     param: encrypted.toString()
  //   }
  //   const response = await fetch(`${URL}/toleAPI/api/inserttbl`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Signature': apisignature
  //     },
  //     body: JSON.stringify(dts) //dts
  //   });
  //   const tole = await response.json();
  //   console.log(tole)
  //   if (tole.STATUS_CODE === "0") {
  //     setReload(!reload)
  //     memberListShown();
  //     setMsg({
  //       msg: "Successfully Added",
  //       type: "alert alert-success",
  //     });
  //   } else if (tole.STATUS_CODE === "1") {
  //     setMsg({
  //       msg: tole.MESSAGE,
  //       type: "alert alert-danger",
  //     });
  //   }
  // }

  // if(FamilyObj === null){
  //   FamilyObj: [
  //     {
  //       RelationID: 0,
  //       Gender: 0,
  //       FullName: obj.Full_name,
  //       CurrentAddress: obj.Current_address,
  //       Profession: 0,
  //       DOB: obj.DOB,
  //       Academic: 0,
  //       CitizenNo: obj.Citizenship_no,
  //       IssuedBy: obj.Citizen_issue_by,
  //       DateOfIssue: obj.Citizenship_issue_date,
  //       PAN: obj.Pan_no,
  //       VoterID: obj.Voter_id,
  //       NationalID: obj.National_id,
  //       Nationality: 0,
  //       Passport: obj.Passport,
  //       Religion: 0,
  //       MaritalStatus: 0,
  //       BloodGroup: 0,
  //       Phone: obj.Phone,
  //       Email: obj.Email,
  //     },
  //   ],
  // }else{
  //   FamilyObj: getFamilyValue,
  // }

  const fetchdata = () => {
    const dataForm = {
      ToleID: ToleID,
      Flag: "i",
      UserID: UserID,
      Title: obj.title_id,
      Gender: obj.gender_id,
      FirstName: obj.first_name,
      MiddleName: obj.middle_name,
      LastName: obj.last_name,
      DobBs: obj.dob_ad,
      DobAd: obj.dob_bs,
      Nationality: obj.nationality_id,
      Religion: obj.religion_id,
      MaritalStatus: obj.material_id,
      BloodGroup: obj.blood_id,
      FatherName: obj.father_name,
      MotherName: obj.mother_name,
      GrandFatherName: obj.grand_father,
      PermProvince: obj.perm_province_id,
      PerDistrict: obj.perm_district_id,
      PerMunicipality: obj.perm_vdc_id,
      PerWard: obj.perm_ward_id,
      PerHouse: obj.perm_house_no,
      TempProvince: obj.temp_province_id,
      TempDistrict: obj.temp_district_id,
      TempMunicipality: obj.temp_vdc_id,
      TempWard: obj.temp_ward_id,
      TempHouse: obj.temp_house_no,
      TemLatitude: obj.temp_latitude,
      TemLongitude: obj.temp_longitude,
      Pan: obj.perm_pan,
      Contact: obj.contact,
      Phone: obj.phone_Home,
      UserName: obj.user_name,
      Password: obj.passport,
      NoToken: "323",
      UserType: "1",
      Email: obj.mail_address,
      UserImage: image !== null ? image.split(",")[1] : "",
      // Profession: getProfessionValue === null ? [] : getProfessionValue,
      Profession: obj.profession,
      PermAddress:obj.perm_address,
      TempAddress:obj.temp_address,
      Source: "Web",
      AllowApp:"Y",
      EdQual: obj.qualification,
      EdStatus: "Y",
      FetchURL: `${urll}/admin/user`,
      Type: "POST",
    };
     console.log(dataForm, "hello");
    Fetchdata(dataForm).then(function (result) {
      console.log(result, "result");
      if (result.StatusCode === 200) {
        memberTypeShown();
        setShowMember(true);
        setMsg({
          msg: result.Message,
          type: "alert alert-success",
        });
      } else {
        setMsg({
          msg: result.Message,
          type: "alert alert-danger",
        });
      }
    });
  };

  //API to get data on dropdown starts here

  //API to hit Member status
  const stateInitial = [];
  const [newStat, setNewStat] = useState(stateInitial);

  const disallowMember = (ID, IsActive) => {
    const dataForm = {
      ToleID: ToleID,
      Flag: "AD",
      UserID: UserID,
      MemID: ID,
      AllowApp: IsActive,
    };
    if (IsActive === "Y") {
      dataForm.AllowApp = "N";
    } else {
      dataForm.AllowApp = "Y";
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/user`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          memberTypeShown();
          let statsN = JSON.parse(JSON.stringify(newStat));
          let pitchStatus;

          if (dataForm.Status === "Y") {
            pitchStatus = "Allow";
          } else if (dataForm.Status === "N") {
            pitchStatus = "Disallow";
          }
          // console.log(pitchStatus);
          setNewStat(statsN);
          setMsg({
            msg: resp.Message,
            type: "alert alert-success",
          });
        } else {
          setMsg({
            msg: resp.Message,
            type: "alert alert-danger",
          });
        }
      });
    });
  };

  const memberStatus = (ID, IsActive) => {
    const dataForm = {
      ToleID: ToleID,
      Flag: "VU",
      UserID: UserID,
      MemberID: ID,
      Status: IsActive,
    };
    if (IsActive === 1) {
      dataForm.Status = 1;
    } else if (IsActive === 2) {
      dataForm.Status = 2;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/user`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          memberTypeShown();
        } else {
        }
      });
    });
  };


  //Professionlist api


  // Update Personal

  const [perID, setPerId] = useState(null);
  const [reBs, setReBs] = useState();

  const handleEdit = (data) => {
    setPerId(data.UserID);
    // console.log(data.UserID)
    setCloseChecked(false);
    $(".viewMemberPop").fadeIn(100);
    setViewPop(true);
    setStep(1);
    // console.log(perID);
    setPdistrictData([]);
    setPmunicipalityData([]);
    setTdistrictData([]);
    setTmunicipalityData([]);
    setPermanentFormError({});
    setTemporaryFormError({});
    setMemberFormErrors({});
    setReBs(Math.random());
    setActiveTab({
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
      tab6: false,
    });
  };

  // const [memberInfo, setMemberInfo] = useState([]);

  // useEffect(() => {

  //   const dataForm = {
  //     ToleID: ToleID,
  //     tblOpt: "22",
  //     tblField: "ID UserID,user_name UserName,mail_address MailAddress,user_photo UserPhoto,first_name FirstName,middle_name MiddleName,last_name LastName,title_id TitleID,gender_id GenderID,nationality_id NationalityID,religion_id ReligionID,material_id MaternalID,blood_id BloodID,dob_bs DobBs,dob_ad DobAd,qualification Qualitification,father_name FatherName,mother_name MotherName,grand_father GrandFather,perm_address PermAdress,perm_province_id PermProvince,perm_zone_id PermZone,perm_district_id PermDistrict,perm_vdc_id PermVDC,perm_ward_id PermWard,perm_pan PermPAN,perm_house_no PermHouse,perm_tole PermTole,perm_latitude PermLat,perm_longitude PermLong,temp_address TempAddress,temp_province_id TempProvince,temp_zone_id TempZone,temp_district_id TempDistrict,temp_vdc_id TempVDC,temp_ward_id TempWard,temp_pan TempPAN,temp_house_no TempHouse,temp_tole TempTole,temp_latitude TempLat,temp_longitude TempLong,citizenship_no CitNo,citizen_issue_by CitBy,citizenship_issue_date CitDate,passport_no PassNo,passport_issue_by PassBy,passport_issue_date PassDate,voter_id VotID,voter_issue_by VotBy,voter_issue_date VotDate,license_no LicenseNo,license_issue_by LicenseBy,license_issue_date LicenseDate,electric_no ElectricNo,electric_issue_by ElectricBy,electric_issue_date ElectricDate",
  //     conditionField: "ID",
  //     conditionValues: perID,
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "Y"
  //   }
  //   var key = CryptoJS.enc.Utf8.parse("D89035A6634F4C4786B947518F17A18A");
  //   var iv = CryptoJS.enc.Utf8.parse("EasyS0ftS0ftware");

  //   var encrypted = CryptoJS.AES.encrypt(JSON.stringify(dataForm), key, {
  //     iv: iv,
  //     mode: CryptoJS.mode.CBC,
  //     padding: CryptoJS.pad.Pkcs7,
  //   });
  //   var encryptedData = encrypted.toString();

  //   const dataToSend = {
  //     param: encryptedData,
  //   };
  //   const eventTitleList = async (dataToSend) => {

  //     const response = await fetch(
  //       `${URL}/toleAPI/api/selectdatawithcondition`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Signature: apisignature,
  //         },
  //         body: JSON.stringify(dataToSend),
  //       }
  //     );
  //     const getData = await response.json();
  //     return getData;
  //   };

  //   eventTitleList(dataToSend).then(function (result) {
  //     const code = CryptoJS.AES.decrypt(result.MESSAGE, key, {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7,
  //     });
  //     const response = JSON.parse(code.toString(CryptoJS.enc.Utf8));
  //     // console.log(response)
  //     if (response.STATUS_CODE === "0") {
  //       const data = response.dataList[0]
  //       setMemberInfo(data)
  //       setMemberFormValue({
  //         fName: data.FirstName,
  //         mName: data.MiddleName,
  //         mAddress: data.MailAddress,
  //         lName: data.LastName,
  //         title: data.TitleID,
  //         gender: data.GenderID,
  //         nationality: data.NationalityID,
  //         religion: data.ReligionID,
  //         marriage: data.MaternalID,
  //         blood: data.BloodID,
  //         bsdate: data.DobBs,
  //         addate: data.DobAd,
  //         qualification: data.Qualitification,
  //         father: data.FatherName,
  //         mother: data.MotherName,
  //         grandfather: data.GrandFather,
  //         provience: data.PermProvince,
  //         zone: data.PermZone,
  //         district: data.PermDistrict,
  //         municipality: data.PermVDC,
  //         ward: data.PermWard,
  //         address: data.PermAdress,
  //         street: data.PermTole,
  //         house: data.PermHouse,
  //         pan: data.PermPAN,
  //         latitude: data.PermLat,
  //         longitude: data.PermLong,
  //         tprovience: data.TempProvince,
  //         tzone: data.TempZone,
  //         tdistrict: data.TempDistrict,
  //         tmunicipality: data.TempVDC,
  //         tward: data.TempWard,
  //         taddress: data.TempAddress,
  //         tstreet: data.TempTole,
  //         thouse: data.TempHouse,
  //         tpan: data.TempPAN,
  //         tlatitude: data.TempLat,
  //         tlongitude: data.TempLong,
  //         citizenship: data.CitNo,
  //         cIssued: data.CitBy,
  //         cDate: data.CitDate,
  //         passport: data.PassNo,
  //         pIssued: data.PassBy,
  //         pDate: data.PassDate,
  //         voter: data.VotID,
  //         vIssued: data.VotBy,
  //         vDate: data.VotDate,
  //         license: data.LicenseNo,
  //         lIssued: data.LicenseBy,
  //         lDate: data.LicenseDate,
  //         electricity: data.ElectricNo,
  //         eIssued: data.ElectricBy,
  //         eDate: data.ElectricDate,
  //       });
  //       memberListShown();
  //     }
  //   });
  // }, [perID]);

  useEffect(() => {
    memberInfo();
  }, [perID]);

  //console.log(perID)

  const memberInfo = () => {
    const params = {
      ToleID: ToleID,
      UserId:UserID,
      Flag: "SI",
      MemID: perID,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    };
    fetch(`${urll}/admin/user`, requestOptions).then((result) => {
      result.json().then((resp) => {
         console.log(resp);
        if (resp.StatusCode === 200) {
          const data = resp.userLst[0] ? resp.userLst[0] : "";
          console.log(data);
          setMemberFormValue({
            fName: data.FirstName,
            mName: data.MiddleName,
            uName: data.Userame,
            lName: data.LastName,
            title: data.Title,
            gender: data.Gender,
            nationality: data.Nationality,
            religion: data.Religion,
            marriage: data.MaritalStatus,
            blood: data.BloodGroup,
            bsdate: data.BirthBS,
            addate: data.BirthAD,
            qualification: data.EdQualification,
            father: data.FatherName,
            mother: data.MotherName,
            grandfather: data.GrandFatherName,
            provience: data.PerProvince,
            zone: data.PerZoneID,
            district: data.PerDistrict,
            municipality: data.PerMunicipality,
            ward: data.PerWard,
            address: data.PermAddress,
            house: data.PerHouse,
            pan: data.PAN,
            // latitude: data.PerLat,
            // longitude: data.PerLong,
            tprovience: data.TempProvince,
            tzone: data.TemZoneID,
            tdistrict: data.TempDistrict,
            tmunicipality: data.TempMunicipality,
            tward: data.TempWard,
            taddress: data.TempStreetID,
            tstreet: data.TemTole,
            thouse: data.TempHouse,
            tlatitude: data.TemLat,
            tlongitude: data.TemLong,
            citizenship: data.CitizenNo,
            cIssued: data.CitizenBy,
            cDate: data.CitizenDate,
            passport: data.PassportNo,
            pIssued: data.PassportBy,
            pDate: data.PassportDate,
            voter: data.VoterID,
            vIssued: data.VoterBy,
            vDate: data.VoterDate,
            license: data.LicenseNo,
            lIssued: data.LicenseBy,
            lDate: data.LicenseDate,
            electricity: data.ElectricNo,
            eIssued: data.ElectricBy,
            eDate: data.ElectricDate,
            phHome: data.PhHome,
            phOffice: data.PhOffice,
            mAddress: data.Email,
            uName: data.UserName,
            relation: data.RelationID,
          });
          setImage(data.Photo);
        }
      });
    });
  };

  const perObj = {
    first_name: memberFormValue.fName,
    middle_name: memberFormValue.mName,
    last_name: memberFormValue.lName,
    user_name: memberFormValue.uName,
    mail_address: memberFormValue.mAddress,
    title_id: memberFormValue.title,
    gender_id: memberFormValue.gender,
    nationality_id: memberFormValue.nationality,
    religion_id: memberFormValue.religion,
    material_id: memberFormValue.marriage,
    blood_id: memberFormValue.blood,
    dob_bs: memberFormValue.bsdate,
    dob_ad: memberFormValue.addate,
    qualification: memberFormValue.qualification,
    father_name: memberFormValue.father,
    mother_name: memberFormValue.mother,
    grand_father: memberFormValue.grandfather,
    perm_pan: memberFormValue.pan,
    phone_home: memberFormValue.phHome,
    phone_office: memberFormValue.phOffice,
  };

  // update Permanent

  const permObj = {
    perm_address: memberFormValue.address,
    perm_province_id: memberFormValue.provience,
    perm_zone_id: memberFormValue.zone,
    perm_district_id: memberFormValue.district,
    perm_vdc_id: memberFormValue.municipality,
    perm_ward_id: memberFormValue.ward,
    perm_house_no: memberFormValue.house,
    // perm_tole: memberFormValue.street,
    perm_latitude: memberFormValue.latitude,
    perm_longitude: memberFormValue.longitude,
  };

  // Update Temporary

  const tempObj = {
    temp_address: memberFormValue.taddress,
    temp_province_id: memberFormValue.tprovience,
    temp_zone_id: memberFormValue.tzone,
    temp_district_id: memberFormValue.tdistrict,
    temp_vdc_id: memberFormValue.tmunicipality,
    temp_ward_id: memberFormValue.tward,
    temp_house_no: memberFormValue.thouse,
    // temp_tole: memberFormValue.tstreet,
    temp_latitude: memberFormValue.tlatitude,
    temp_longitude: memberFormValue.tlongitude,
  };

  // Update Family

  // const famObj = {
  //   full_name: memberFormValue.fullName,
  //   current_address: memberFormValue.cAddress,
  //   relation_id: memberFormValue.relation,
  //   gender_id: memberFormValue.gender,
  //   phone: memberFormValue.pNumber,
  //   DOB: memberFormValue.dob,
  //   email: memberFormValue.email,
  //   profession_id: memberFormValue.profession,
  //   academic_id: memberFormValue.academic,
  //   blood_id: memberFormValue.blood,
  //   nationlity_id: memberFormValue.nationality,
  //   religion_id: memberFormValue.religion,
  //   marital_id: memberFormValue.marriage,
  //   citizenship_no: memberFormValue.citizenship,
  //   citizen_issue_by: memberFormValue.cIssued,
  //   citizenship_issue_date: memberFormValue.cDate,
  //   voter_id: memberFormValue.vid,
  //   nationa_id: memberFormValue.nid,
  //   pan_no: memberFormValue.pan,
  // };

  //Update Other
  const othObj = {
    citizenship_no: memberFormValue.citizenship,
    citizen_issue_by: memberFormValue.cIssued,
    citizenship_issue_date: memberFormValue.cDate,
    passport_no: memberFormValue.passport,
    passport_issue_by: memberFormValue.pIssued,
    passport_issue_date: memberFormValue.pDate,
    voter_id: memberFormValue.voter,
    voter_issue_by: memberFormValue.vIssued,
    voter_issue_date: memberFormValue.vDate,
    license_no: memberFormValue.license,
    license_issue_by: memberFormValue.lIssued,
    license_issue_date: memberFormValue.lDate,
    electric_no: memberFormValue.electricity,
    electric_issue_by: memberFormValue.eIssued,
    electric_issue_date: memberFormValue.eDate,
  };

  const editdata = () => {
    const dataForm = {
      ToleID: obj.tole_id,
      Flag: "U",
      UserID: UserID,
      MemID: perID,
      TitleID: obj.title_id,
      GenderID: obj.gender_id,
      FirstName: obj.first_name,
      MiddleName: obj.middle_name,
      LastName: obj.last_name,
      DOBAD: obj.dob_ad,
      DOBBS: obj.dob_bs,
      NationalityID: obj.nationality_id,
      ReligionID: obj.religion_id,
      MaritalID: obj.material_id,
      BloodID: obj.blood_id,
      FatherName: obj.father_name,
      MotherName: obj.mother_name,
      GrandFatherName: obj.grand_father,
      CitizenNo: obj.citizenship_no,
      CitizenBy: obj.citizen_issue_by,
      CitizenDate: obj.citizenship_issue_date,
      Qualification: obj.qualification,
      PermStreetID: obj.perm_address,
      PerAddress: "",
      PerProvince: obj.perm_province_id,
      PerZone: "",
      PerDistrict: obj.perm_district_id,
      PerVDC: obj.perm_vdc_id,
      PerWard: obj.perm_ward_id,
      PerHouseNo: obj.perm_house_no,
      PerTole: obj.perm_tole,
      PerLatitude: obj.perm_latitude,
      PerLongitude: obj.perm_longitude,
      TempStreetID: obj.temp_address,
      TemAddress: "",
      TemProvince: obj.temp_province_id,
      TemZone: "",
      TemDistrict: obj.temp_district_id,
      TemVDC: obj.temp_vdc_id,
      TemWard: obj.temp_ward_id,
      TemHouse: obj.temp_house_no,
      TemTole: obj.temp_tole,
      TemLatitude: obj.temp_latitude,
      TemLongitude: obj.temp_longitude,
      PAN: obj.perm_pan,
      PhHome: obj.phone_Home,
      PhOffice: obj.phone_Office,
      UserName: obj.user_name,
      Password: ToleID,
      NotToken: "1",
      DevSource: "1",
      UserType: "1",
      Email: obj.mail_address,
      // UserImage: image !== null ? image.split(",")[1] : "",
      FetchURL: `${urll}/admin/user`,
      Type: "POST",
    };
    // console.log(dataForm);
    Fetchdata(dataForm).then(function (result) {
      // console.log(result);
      if (result.StatusCode === 200) {
        memberTypeShown();
        setMsg({
          msg: result.Message,
          type: "alert alert-success",
        });
      } else {
        setMsg({
          msg: result.Message,
          type: "alert alert-danger",
        });
      }
    });
  };

  // const othobj1 = Object.values(othObj)
  // const othobj2 = othobj1.join("##");
  // const editOtherData = async () => {

  //   const dataForm = {
  //     ToleID: ToleID,
  //     userID: UserID.toString(),
  //     tblOpt: "22",
  //     tblField: "citizenship_no,citizen_issue_by,citizenship_issue_date,passport_no,passport_issue_by,passport_issue_date,voter_id,voter_issue_by,voter_issue_date,license_no,license_issue_by,license_issue_date,electric_no,electric_issue_by,electric_issue_date",
  //     tblValues: othobj2,
  //     tblID: perID.toString(),
  //     validationFields: "-",
  //     fieldforList: "-",
  //     orderbyField: "-",
  //     DateTime: "2022/02/03 03:00 AM",
  //     Flag: "Y"
  //   }
  //   var key = CryptoJS.enc.Utf8.parse('D89035A6634F4C4786B947518F17A18A');
  //   var iv = CryptoJS.enc.Utf8.parse('EasyS0ftS0ftware');

  //   var encrypted = CryptoJS.AES.encrypt(JSON.stringify(dataForm), key, //dataForm
  //     {
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7
  //     });

  //   console.log("encplogin", encrypted.toString())
  //   const dts = {
  //     param: encrypted.toString()
  //   }
  //   const response = await fetch(`${URL}/toleAPI/api/updatetbl`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Signature': apisignature
  //     },
  //     body: JSON.stringify(dts) //dts
  //   });
  //   const tole = await response.json();
  //   console.log(tole)
  //   if (tole.STATUS_CODE === "0") {
  //     memberListShown();
  //     setReload(!reload)
  //     setMsg({
  //       msg: "Successfully Edited",
  //       type: "alert alert-success",
  //     });
  //   } else if (tole.STATUS_CODE === "1") {
  //     setMsg({
  //       msg: tole.MESSAGE,
  //       type: "alert alert-danger",
  //     });
  //   }
  // }

  const [resetPopup, setResetPopup] = useState({
    show: false, // initial values set to false and null
    data: null,
  });

  const [Acdata, setACData] = useState();

  const handleReset = (data) => {
    setACData(data.UserID);
    setResetPopup({
      show: true,
      data,
    });
  };

  const [generatedPassword, setGeneratedPassword] = useState('');

  // Function to randomly generate a unique password
  const generatePassword = () => {
    const length = 12;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    setGeneratedPassword(password);
  };

  useEffect(() => {
    generatePassword(); // Automatically generate the password when the component mounts
  }, []);

  const handleResetTrue = () => {
    if (!generatedPassword) {
      console.error('No password generated yet');
      return;
    }
    const dataForm = {
      ToleID: ToleID,
      UserID: UserID,
      MemID: Acdata,
      Flag: "RP",
      Password: generatedPassword, // Use the generated password here
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm),
    };
    fetch(`${urll}/admin/user`, requestOptions).then((result) => {
      result.json().then((resp) => {
        if (resp.StatusCode === 200) {
          memberTypeShown();
          setMsg({
            msg: resp.Message,
            type: "alert alert-success",
          });
          setResetPopup({
            show: false,
            data: null,
          });
        } else {
          setMsg({
            msg: resp.Message,
            type: "alert alert-danger",
          });
        }
      });
    });
  };
  const handleResetFalse = () => {
    setResetPopup({
      show: false,
      data: null,
    });
  };

  // const [locationData, setLocationData] = useState([]);
  // const getLocation = () => {
  //   fetch(`${urll}/loc`).then((result) => {
  //     result.json().then((resp) => {
  //       console.log(resp);
  //       setLocationData(resp);
  //     });
  //   });
  // };

  // useEffect(() => {
  //   getLocation();
  // }, []);
  // console.log(locationData, "yolo");

  // to get location
  const [provinceData, setProvinceData] = useState([]);
  useEffect(() => {
    getProvince();
  }, [reload]);


  const getProvince = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "SP",
    };
    
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm), // Corrected this to use dataForm
    };
    
    fetch(`${urll}/admin/ref-value`, requestOptions)
      .then((result) => result.json()) // Parse the JSON from the response
      .then((resp) => {
        // Now check the parsed response
        if (resp.StatusCode === 200) {
          setProvinceData(resp.RefLst);
        } else {
          setProvinceData([]);
        }
      })
      .catch((err) => console.log("error: " + err));
  };
  

  const [tdistrictData, setTdistrictData] = useState([]);
  const getTProvince = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "SD",
       ProvinceID: -1
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm), // Corrected this to use dataForm
    };

    fetch(`${urll}/admin/ref-value`, requestOptions)

      .then((result) => result.json())
      .then((resp) => {
        // console.log("District", res.UserLst);
        if (resp.StatusCode === 200) {
          setTdistrictData(resp.RefLst);
        } else {
          setTdistrictData([]);
        }
      })
      .catch((err) => console.log("error" + err));
  };

  const [tmunicipalityData, setTmunicipalityData] = useState([]);
  const getTDistrict = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "SM",
       ProvinceID: 1,
       DistrictID: 1
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm), // Corrected this to use dataForm
    };

    fetch(`${urll}/admin/ref-value`, requestOptions)
      .then((result) => result.json())
      .then((resp) => {
        // console.log("m", res.UserLst);
        if (resp.StatusCode === 200) {
          setTmunicipalityData(resp.RefLst);
        } else {
          setTmunicipalityData([]);
        }
      })
      .catch((err) => console.log("error" + err));
  };

  const [pdistrictData, setPdistrictData] = useState([]);
  const getPProvince = () => {
    const dataForm = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "SD",
       ProvinceID: -1
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm), // Corrected this to use dataForm
    };
    fetch(`${urll}/admin/ref-value`, requestOptions)
      .then((result) => result.json())
      .then((resp) => {
        // console.log("District", res.UserLst);

        if (resp.StatusCode === 200) {
          setPdistrictData(resp.RefLst);
        } else {
          setPdistrictData([]);
        }
      })
      .catch((err) => console.log("error" + err));
  };

  const [pmunicipalityData, setPmunicipalityData] = useState([]);
  const getPDistrict = () => {

    const dataForm = {
      ToleID: ToleID,
      UserID: UserID,
      Flag: "SM",
       ProvinceID: 1,
       DistrictID: 1
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataForm), // Corrected this to use dataForm
    };
    fetch(`${urll}/admin/ref-value`, requestOptions)
      .then((result) => result.json())
      .then((resp) => {
        // console.log("m", res.UserLst);

        if (resp.StatusCode === 200) {
          setPmunicipalityData(resp.RefLst);
        } else {
          setPmunicipalityData([]);
        }
      })
      .catch((err) => console.log("error" + err));
  };

  const [def, setDef] = useState();


  //to delete
     // This will perform the deletion and hide the Confirmation Box
     const [delPopup, setDelPopup] = useState({
      show: false, // initial values set to false and null
      data: null,
    });
    const [Accdata, setACCData] = useState();
    const handleDelete = (data) => {
      setACCData(data);
  
      setDelPopup({
        show: true,
        data,
      });
    };
    const handleDeleteTrue = () => {
      const dataForm = {
        ToleID: ToleID,
        UserID: UserID,
        MemID:Accdata,
        Flag: "R",
       
      };
      console.log(dataForm);
  
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataForm),
      };
      fetch(`${urll}/admin/user`, requestOptions).then((result) => {
        result.json().then((resp) => {
          if (resp.StatusCode === 200) {
            memberTypeShown();
            setMsg({
              msg: resp.Message,
              type: "alert alert-success",
            });
            setDelPopup({
              show: false,
              data: null,
            });
          } else {
            setMsg({
              msg: resp.Message,
              type: "alert alert-danger",
            });
          }
        });
      });
    };

     // This will just hide the Confirmation Box when user clicks "No"/"Cancel"

  const handleDeleteFalse = () => {
    setDelPopup({
      show: false,
      data: null,
    });
  };
  

  return (
    <MemberContext.Provider
      value={{
        setReBs,
        reBs,
        def,
        setDef,
        memberStatus,
        activeTab,
        setActiveTab,
        popup,
        setPopup,
        membervalue,
        memberFormValue,
        setMemberFormValue,
        memberType,
        setMemberType,
        isSubmit,
        setIsSubmit,
        currentStep,
        setStep,
        memberFormErrors,
        setMemberFormErrors,
        permanentIsSubmit,
        setPermanentIsSubmit,
        permanentFormError,
        setPermanentFormError,
        temporaryIsSubmit,
        setTemporaryIsSubmit,
        temporaryFormError,
        setTemporaryFormError,
        familyObject,
        setFamilyObject,
        formValue1,
        familyList1,
        setFamilyList1,
        familyFormError1,
        setFamilyFormError1,
        familyFormValue1,
        setFamilyFormValue1,
        isUploaded1,
        setIsUploaded1,
        typeFile1,
        setTypeFile1,
        setCloseChecked1,
        closeChecked1,
        setFamilySubmit1,
        familySubmit1,
        setFamPopup1,
        famPopup1,
        setImage1,
        image1,
        professionFormValue2,
        setProfessionFormValue2,
        professionvalue2,
        closeChecked2,
        setCloseChecked2,
        popup2,
        setPopup2,
        professionFormError2,
        setProfessionFormError2,
        professionSubmit2,
        setProfessionSubmit2,
        proList2,
        setProList2,
        finalSubmit,
        setFinalSubmit,
        finalFormError,
        setFinalFormError,
        viewPop,
        setViewPop,
        perEditSubmit,
        setPerEditSubmit,
        tempSubmit,
        setTempSubmit,
        perSubmit,
        setPerSubmit,
        otherSubmit,
        setOtherSubmit,
        reload,
        setReload,
        disallowMember,
        memberInfo,
        handleEdit,
        memberList,
        closeChecked,
        setCloseChecked,
        loading,
        image,
        setImage,
        isUploaded,
        setIsUploaded,
        perID,
        typeFile,
        setTypeFile,
        fetchdata,
        editdata,
        handleReset,
        resetPopup,
        handleResetTrue,
        handleResetFalse,
        re,
        setRe,
        provinceData,
        setProvinceData,
        tdistrictData,
        setTdistrictData,
        getTProvince,
        getPProvince,
        getTDistrict,
        getPDistrict,
        tmunicipalityData,
        setTmunicipalityData,
        pdistrictData,
        setPdistrictData,
        pmunicipalityData,
        setPmunicipalityData,
        // familyList,
        // setFamilyList,
        // getProvince,
        memberDrop,
        setMemberDrop,
        generatedPassword,
        handleDeleteTrue,
        handleDeleteFalse,
        delPopup,
        handleDelete,
        professionDrop
      }}
    >
      {props.children}
    </MemberContext.Provider>
  );
}

export default MemberState;
