import React, { useContext, useEffect, useState, useRef } from "react";
import "../../css/Complain.css";
import GovernmentContext from "../contextFolder/GovernmentContextFolder/GovernmentContext";
import GovernmentDelete from "./GovernmentDelete";
import SidebarContext from "../sidebarcontext/SidebarContext";
import GovernmentEditPop from "./GovernmentEditPop";


const GovernmentTable = ({ searchTerm }) => {
  const {
    mode,
    tableHeadBg,
    tableHeadWidthBg,
    tableDataBg,
    darkText,
  } = useContext(SidebarContext);
  const context = useContext(GovernmentContext);
  const {
    // handleViewComplain,
    // loading,
    handleDelete,
    handleEditGovernment,
    governmentList,
    handleDeleteTrue,
    handleDeleteFalse,
    delPopup,
    deactivateGovernment,
    editPop,
    setEditPop,
  } = context;

  //For Status
  const changeStatus = (GovernmentID, IsActive) => {
    deactivateGovernment(GovernmentID, IsActive);
  };

  const checkStatus = (IsActive) => {
    if (IsActive === "A") {
      return "Deactivate";
    } else if (IsActive === "I") {
      return "Activate";
    }
  };

  const componentRef = useRef();
  const ref = React.createRef();
  const options = {
    orientation: "landscape",
    unit: "in",
    format: [12, 12],
  };

  if (governmentList.length > 0) {
    return (
      <>
        <div className="outer-wrapper">
          <div
            className="table-wrapper"
            style={{
              overflowX: "scroll",
              overflowY: "scroll",
              margin: "3px",
              height: "360px",
            }}
          >
            
              <div>
                {/* <div className="table__button__wrapper">
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText={<RiFileExcel2Fill style={{ color: mode === "light" ? "#555" : "#fff" }}/>}
                  />

                  <Pdf
                    targetRef={ref}
                    filename="code-example.pdf"
                    options={options}
                    x={0.5}
                    y={0.5}
                    scale={0.8}
                  >
                    {({ toPdf }) => (
                      <button onClick={toPdf}>
                        <BsFileEarmarkPdfFill style={{ color: mode === "light" ? "#555" : "#fff" }}/>
                      </button>
                    )}
                  </Pdf>

                  <ReactToPrint
                    trigger={() => {
                      return (
                        <button>
                          <AiFillPrinter style={{ color: mode === "light" ? "#555" : "#fff" }}/>
                        </button>
                      );
                    }}
                    content={() => componentRef.current}
                    documentTitle="new document"
                    pageStyle="print"
                  />
                </div> */}
                <div ref={ref}>
                  <table ref={componentRef} className="table" id="table-to-xls">
                    <thead>
                      <tr className="tableHead">
                        <td style={tableHeadBg}>S.N.</td>
                        <td style={tableHeadBg}>Type</td>
                        <td style={tableHeadBg}>Identity No</td>
                        <td style={tableHeadBg}>Date</td>
                        <td style={tableHeadBg}> By</td>
                        <td style={tableHeadBg}>Status</td>
                        <td style={tableHeadWidthBg}> Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {governmentList
                        .filter((val) => {
                          if (searchTerm === "") {
                            return val;
                          } else if (
                            {
                              /* val.MemberName.toLowerCase().includes(
                        searchTerm.toLowerCase()
                      ) */
                            } ||
                            val.Topic.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            ) ||
                            val.Title.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            ) ||
                            val.ComplainDate.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            )
                          ) {
                            return val;
                          }
                        })
                        .map((item, i) => (
                          <tr key={i}>
                            <td style={tableDataBg}>{i + 1}</td>
                            <td style={tableDataBg}>{item.IdenType}</td>
                            <td style={tableDataBg}>{item.IdentNo}</td>
                            <td style={tableDataBg}>{item.IdentDate}</td> 
                             <td style={tableDataBg}> {item.IdentBy}</td>
                             <td style={tableDataBg}>
                            <span
                              className="rejectspan badge"
                              onClick={() =>
                                changeStatus(item.IdentID, item.IsActive)
                              }
                              style={darkText}
                            >
                             {checkStatus(item.IsActive)}
                            </span>

                          </td>
                            <td style={darkText}>
                              <span
                                className="editspan badge"
                                onClick={() => handleEditGovernment(item)}
                                style={darkText}
                              >
                            Edit
                              </span>

                              <span
                                className="editspan badge"
                                onClick={() => handleDelete(item.IdentID)}
                                style={darkText}
                              >
                            Remove
                              </span>
                            </td>

                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            
          </div>
        </div>
        <GovernmentEditPop governmentTrigger={editPop} setGovernmentTrigger={setEditPop} />
        {delPopup.show && (
          <GovernmentDelete
            handleDeleteTrue={handleDeleteTrue}
            handleDeleteFalse={handleDeleteFalse}
          />
        )}



      </>
    );
  } else {
    return (
      <div className="outer-wrapper">
        <div
          className="table-wrapper"
          style={{
            overflowX: "scroll",
            overflowY: "scroll",
            margin: "3px",
            height: "300px",
          }}
        >
          <div>
            <div className="table__button__wrapper">
              {/* <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="table-to-xls"
                filename="tablexls"
                sheet="tablexls"
                buttonText={<RiFileExcel2Fill style={{ color: mode === "light" ? "#555" : "#fff" }}/>}
              />

              <Pdf
                targetRef={ref}
                filename="code-example.pdf"
                options={options}
                x={0.5}
                y={0.5}
                scale={0.8}
              >
                {({ toPdf }) => (
                  <button onClick={toPdf}>
                    <BsFileEarmarkPdfFill style={{ color: mode === "light" ? "#555" : "#fff" }}/>
                  </button>
                )}
              </Pdf>

              <ReactToPrint
                trigger={() => {
                  return (
                    <button>
                      <AiFillPrinter style={{ color: mode === "light" ? "#555" : "#fff" }}/>
                    </button>
                  );
                }}
                content={() => componentRef.current}
                documentTitle="new document"
                pageStyle="print"
              /> */}
            </div>
            <div ref={ref}>
              <table ref={componentRef} className="table" id="table-to-xls">
                <thead>
                  <tr className="tableHead">
                    <td style={tableHeadBg}>S.N.</td>
                    <td style={tableHeadBg}>Name</td>
                    <td style={tableHeadBg}>Topic</td>
                    <td style={tableHeadBg}>Title</td>
                    <td style={tableHeadBg}> Description</td>
                    <td style={tableHeadBg}>Status</td>
                    <td style={tableHeadWidthBg}> Action</td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div className="text-center mt-4">
            <p style={darkText}> No data found</p>
          </div>
        </div>
      </div>
    );
  }
};

export default GovernmentTable;
