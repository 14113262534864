import React, { useContext, useState, useEffect, useRef } from "react";
// import cooperativeContext from '../component/Cooperative/cooperativeContext';
import $ from "jquery";
// import Spinner from '../component/Spinner/Spinner';
import "../../css/imagePreview.css";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";
import CryptoJS from "crypto-js";
import UseHttp from "../hooks/Use-http";
import Plus from "../../images/Plus.png";
import CloseIcon from "../../images/CloseIcon.svg";
import { GetEnglishDate } from "../hooks/dateConvertor";
// import NepaliDate from "nepali-date-converter";
import NepaliDate from "nepali-date-converter";
import SidebarContext from "../sidebarcontext/SidebarContext";
import RelationPopup from "../ProfessionPage/RelationPopup";
import ProfessionContext from "../contextFolder/ProfessionContextFolder/ProfessionContext";

export default function Personal(props) {
  const apisignature = process.env.REACT_APP_SIGNATURE;
  // const { relationDropdown } = useContext(ProfessionContext);
  const { relationPopup, setRelationPopup, setPopup } =
    useContext(SidebarContext);
  const context = useContext(MemberContext);
  const {
    memberFormValue,
    setMemberFormValue,
    isSubmit,
    setIsSubmit,
    setStep,
    userdata,
    setUserData,
    memberFormErrors,
    setMemberFormErrors,
    currentStep,
    setPermanentFormError,
    setPermanentIsSubmit,
    setTemporaryFormError,
    setTemporaryIsSubmit,
    setFinalSubmit,
    membervalue,
    fetchdata,
    setReload,
    reload,
    closeChecked,
    setCloseChecked,
    image,
    setImage,
    isUploaded,
    setIsUploaded,
    typeFile,
    setTypeFile,
    def,
    professionDrop
  } = context;
  const { sendRequest } = UseHttp();

  //   const { adToBs, bsToAd } = require("@sbmdkl/nepali-date-converter");
  //   try {
  //     const bsDate = adToBs("2022-08-04");
  //     console.log(bsDate);

  //     const adDate = bsToAd("2078-03-05");
  //     console.log(adDate);
  //   } catch (e) {
  //     console.log(e.message);
  //   }
  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();
  const inputRef5 = useRef();
  const inputRef6 = useRef();
  const inputRef7 = useRef();
  const inputRef8 = useRef();
  const inputRef9 = useRef();
  const inputRef10 = useRef();
  const inputRef11 = useRef();
  const inputRef12 = useRef();
  // const inputRef13 = useRef();

  const closePopup = (e) => {
    e.preventDefault();
    $(".displayPopup").fadeOut(100);
    setPopup(false);
    setMemberFormValue(membervalue);
    setMemberFormErrors({});
    setIsSubmit(false);
    setPermanentFormError({});
    setPermanentIsSubmit(false);
    setTemporaryFormError({});
    setTemporaryIsSubmit(false);
    setFinalSubmit(false);
    setStep(1);
  };

  const formNext = (e) => {
    e.preventDefault();
    setMemberFormErrors(validate(memberFormValue));
    setIsSubmit(true);
  };
  useEffect(() => {
    if (Object.keys(memberFormErrors).length === 0 && isSubmit) {
      console.log(memberFormValue, image);
      setStep(2);
    }
    setIsSubmit(false);
  }, [memberFormErrors]);

  const handleDate = ({ bsDate,adDate }) => {
    setMemberFormValue({ ...memberFormValue, bsdate: bsDate, addate: adDate });
  };

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    setMemberFormValue({ ...memberFormValue, [name]: value });
  };
  const handleOnChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleRelationPopup = (e) => {
    e.preventDefault();
    setRelationPopup(true);
    $(".relationTopicPop").fadeIn(100);
    // setProfessionFormValue(professionvalue);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    // if (!values.addate) {
    //   errors.addate = "Required";
    //   inputRef11.current.focus();
    // }
    if (!values.blood) {
      errors.blood = "Required";
      inputRef9.current.focus();
    }
    if (!values.marriage) {
      errors.marriage = "Required";
      inputRef8.current.focus();
    }
    if (!values.religion) {
      errors.religion = "Required";
      inputRef7.current.focus();
    }
    if (!values.nationality) {
      errors.nationality = "Required";
      inputRef6.current.focus();
    }
    if (!values.gender) {
      errors.gender = "Required";
      inputRef5.current.focus();
    }
    if (!values.title) {
      errors.title = "Required";
      inputRef4.current.focus();
    }
    // if (!values.relation) {
    //   errors.relation = "Required";
    //   inputRef13.current.focus();
    // }
    // if (!values.uName) {
    //   errors.uName = "Required";
    //   inputRef3.current.focus();
    // }
    if (!values.lName) {
      errors.lName = "Required";
      inputRef2.current.focus();
    }
    if (!values.fName) {
      errors.fName = "Required";
      inputRef1.current.focus();
    }

    if (!values.bsdate) {
      errors.bsdate = "Required";
      // inputRef10.current.focus();
    }
    if (values.mAddress.length === 0) {
      setIsSubmit(true);
    } else if (!regex.test(values.mAddress)) {
      errors.mAddress = "This is not a valid email format";
      inputRef12.current.focus();
    }
    return errors;
  };

  //Api to get title list
  // useEffect(() => {

  // }, [titleList])

  function handleImageChange(e) {
    if (e.target.files && e.target.files[0]) {
      setTypeFile(e.target.files[0].type);
      let reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUploaded(true);
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  }

  return (
    <>
      <div
        className="container-fluid basicform"
        style={{ paddingBottom: "20px" }}
      >
        <form>
          <div className="form-row">
            <div className="form-group col-md-4">
              <label className="form-label" htmlFor="fName">
                First Name <sup className="sup-col">*</sup>
              </label>

              <input
                type="text"
                name="fName"
                onChange={handleChange}
                value={memberFormValue.fName}
                className="form-control form-control-sm mb-1"
                id="fName"
                ref={inputRef1}
                required
              />
              <p className="errormsg ">{memberFormErrors.fName}</p>
            </div>

            <div className="form-group col-md-4  ">
              <label className="form-label" htmlFor="mName">
                Middle Name
              </label>
              <input
                type="text"
                name="mName"
                onChange={handleChange}
                value={memberFormValue.mName}
                className="form-control form-control-sm mb-1"
                id="mName"
              />
            </div>

            <div className="form-group col-md-4  ">
              <label className="form-label" htmlFor="lName">
                Last Name <sup className="sup-col">*</sup>
              </label>
              <input
                type="text"
                name="lName"
                onChange={handleChange}
                value={memberFormValue.lName}
                className="form-control form-control-sm mb-1"
                id="lName"
                ref={inputRef2}
                required
              />
              <p className="errormsg ">{memberFormErrors.lName}</p>
            </div>
          </div>

          <div className="form-row">
            {/* <div className="form-group col-md-4" style={{ margin: "0px" }}>
              <label className="form-label" htmlFor="uName">
                User Name <sup className="sup-col">*</sup>
              </label>

              <input
                type="text"
                name="uName"
                onChange={handleChange}
                value={memberFormValue.uName}
                className="form-control form-control-sm mb-1"
                id="uName"
                ref={inputRef3}
                required
              />
              <p className="errormsg ">{memberFormErrors.uName}</p>
            </div> */}

            <div className="form-group col-md-4" style={{ margin: "0px" }}>
              <label className="form-label" htmlFor="mAddress">
                Mail Address
              </label>
              <input
                type="text"
                name="mAddress"
                onChange={handleChange}
                value={memberFormValue.mAddress}
                className="form-control form-control-sm mb-1"
                id="mAddress"
                ref={inputRef12}
              />
              <p className="errormsg ">{memberFormErrors.mAddress}</p>
            </div>



            <div className="form-group col-md-4" style={{ margin: "0px" }}>
              <label className="form-label" htmlFor="mAddress">
                Contact
              </label>
              <input
                type="text"
                name="mAddress"
                onChange={handleChange}
                value={memberFormValue.contact}
                className="form-control form-control-sm mb-1"
                id="mAddress"
                ref={inputRef12}
              />
              <p className="errormsg ">{memberFormErrors.contact}</p>
            </div>

            <div className="form-group col-md-4">
              <label className="form-label" htmlFor="phHome">
                Phone
              </label>

              <input
                type="text"
                name="phHome"
                onChange={handleChange}
                value={memberFormValue.phHome}
                className="form-control form-control-sm mb-1"
                id="phHome"
              />
            </div>

            {/* <div className="form-group col-md-4" style={{ margin: "0px" }}>
              <label className="form-label" htmlFor="relation">
                Relation <sup className="sup-col">*</sup>
              </label>
              <div className="uk-flex">
                <select
                  id="relation"
                  name="relation"
                  value={memberFormValue.relation}
                  onChange={handleChange}
                  className="form-control form-control-sm mb-1"
                  style={{ fontSize: "12px" }}
                  ref={inputRef13}
                  required
                >
                  <option value="" selected disabled>
                    Select Relation
                  </option> */}
                  {/* <option value="1">Father</option>
                  <option value="2">Mother</option>
                  <option value="3">Brother</option>
                  <option value="4">Sister</option>
                  <option value="5">Husband</option>
                  <option value="6">Wife</option>
                  <option value="7">GrandFather</option>
                  <option value="8">GrandMother</option>
                  <option value="9">Uncle</option>
                  <option value="10">Aunty</option>
                  <option value="11">Brother in Law</option>
                  <option value="12">Sister in Law</option>
                  <option value="13">Maternal Uncle</option> */}
                  {/* {relationDropdown.map((item, i) => (
                    <option value={item.ID}>{item.Name}</option>
                  ))} */}
                {/* </select> */}
                {/* <i
                    style={{ paddingTop: "4px", color: "#999" }}
                    className="fas fa-angle-down notificationpositondrop"
                  ></i> */}
                {/* <span>
                  <button
                    onClick={handleRelationPopup}
                    type="button"
                    className="btn btn-primary"
                  >
                    +
                  </button>
                </span>
              </div>
              <p className="errormsg ">{memberFormErrors.relation}</p>
            </div> */}
          </div>

          <div className="form-row">
            <div className="form-group col-md-4">
              <label className="form-label" htmlFor="title">
                Title <sup className="sup-col">*</sup>
              </label>
              <select
                id="title"
                name="title"
                onChange={handleChange}
                value={memberFormValue.title}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
                ref={inputRef4}
                required
              >
                <option value="" selected disabled>
                  Select Title
                </option>
                <option value="1">Mr.</option>
                <option value="2">Mrs.</option>
                <option value="3">Ms.</option>
              </select>
              <i
                style={{ paddingTop: "4px", color: "#999" }}
                className="fas fa-angle-down notificationpositondrop"
              ></i>
              <p className="errormsg ">{memberFormErrors.title}</p>
            </div>

            <div className="form-group col-md-4  ">
              <label className="form-label" htmlFor="gender">
                Gender<sup className="sup-col">*</sup>
              </label>
              <select
                id="gender"
                name="gender"
                onChange={handleChange}
                value={memberFormValue.gender}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
                ref={inputRef5}
                required
              >
                <option value="" selected disabled>
                  Select Gender
                </option>
                <option value="1">Male</option>
                <option value="2">Female</option>
                <option value="3">Other</option>
              </select>
              <i
                style={{ paddingTop: "4px", color: "#999" }}
                className="fas fa-angle-down notificationpositondrop"
              ></i>
              <p className="errormsg ">{memberFormErrors.gender}</p>
            </div>

            <div className="form-group col-md-4  ">
              <label className="form-label" htmlFor="nationality">
                Nationality<sup className="sup-col">*</sup>
              </label>
              <select
                id="nationality"
                name="nationality"
                onChange={handleChange}
                value={memberFormValue.nationality}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
                ref={inputRef6}
                required
              >
                <option value="" selected disabled>
                  Select Nationality
                </option>
                <option value="1">Nepali</option>
              </select>
              <i
                style={{ paddingTop: "4px", color: "#999" }}
                className="fas fa-angle-down notificationpositondrop"
              ></i>
              <p className="errormsg ">{memberFormErrors.nationality}</p>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-4">
              <label className="form-label" htmlFor="religion">
                Religion<sup className="sup-col">*</sup>
              </label>
              <select
                id="religion"
                name="religion"
                onChange={handleChange}
                value={memberFormValue.religion}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
                ref={inputRef7}
                required
              >
                <option value="" selected disabled>
                  Select Religion
                </option>
                <option value="1">Hindu</option>
                <option value="2">Buddhist</option>
                <option value="3">Christian</option>
                <option value="4">Muslim</option>
                <option value="5">Kirat</option>
                <option value="6">Other</option>
              </select>
              <i
                style={{ paddingTop: "4px", color: "#999" }}
                className="fas fa-angle-down notificationpositondrop"
              ></i>
              <p className="errormsg ">{memberFormErrors.religion}</p>
            </div>

            <div className="form-group col-md-4  ">
              <label className="form-label" htmlFor="marriage">
                Marital Status<sup className="sup-col">*</sup>
              </label>
              <select
                id="marriage"
                name="marriage"
                onChange={handleChange}
                value={memberFormValue.marriage}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
                ref={inputRef8}
                required
              >
                <option value="" selected disabled>
                  Select Marital Status
                </option>
                <option value="1">Single</option>
                <option value="2">Married</option>
                <option value="3">Widowed</option>
                <option value="4">Divorced</option>
              </select>
              <i
                style={{ paddingTop: "4px", color: "#999" }}
                className="fas fa-angle-down notificationpositondrop"
              ></i>
              <p className="errormsg ">{memberFormErrors.marriage}</p>
            </div>

            <div className="form-group col-md-4  ">
              <label className="form-label" htmlFor="blood">
                Blood Group<sup className="sup-col">*</sup>
              </label>
              <select
                id="blood"
                name="blood"
                onChange={handleChange}
                value={memberFormValue.blood}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
                ref={inputRef9}
                required
              >
                <option value="" selected disabled>
                  Select Blood Group
                </option>
                <option value="1">A+</option>
                <option value="2">A-</option>
                <option value="3">B+</option>
                <option value="4">B-</option>
                <option value="5">O+</option>
                <option value="6">O-</option>
                <option value="7">AB+</option>
                <option value="8">AB-</option>
              </select>
              <i
                style={{ paddingTop: "4px", color: "#999" }}
                className="fas fa-angle-down notificationpositondrop"
              ></i>
              <p className="errormsg ">{memberFormErrors.blood}</p>
            </div>




          </div>

          <div className="form-row">
            <div className="form-group col-md-4">
              <label className="form-label" htmlFor="bsdate">
                {" "}
                Birth Date (BS)<sup className="sup-col">*</sup>
              </label>

              <Calendar
                className="form-control form-control-sm mb-1"
                dateFormat="MM/DD/YYYY"
                theme="default"
                language="en"
                value={memberFormValue.bsdate}
                onChange={handleDate}
                hideDefaultValue={true}
                placeholder="mm/dd/yyyy"
                ref={inputRef10}
                name="bsdate"
                id="bsdate"
                required
                key={def}
              />
              <p className="errormsg ">{memberFormErrors.bsdate}</p>
            </div>

            <div className="form-group col-md-4  ">
              <label className="form-label" htmlFor="addate">
                Birth Date (AD)<sup className="sup-col">*</sup>
              </label>
              <input
                type="date"
                name="addate"
                onChange={handleChange}
                value={memberFormValue.addate}
                // defaultValue="1999-02-11"
                className="form-control form-control-sm mb-1"
                id="date"
                ref={inputRef11}
                readOnly
              />
              <p className="errormsg ">{memberFormErrors.addate}</p>
            </div>


            <div className="form-group col-md-4">
              <label className="form-label" htmlFor="qualification">
                Select Academic Qualification<sup className="sup-col">*</sup>
              </label>
              <select
                id="qualification"
                name="qualification"
                onChange={handleChange}
                value={memberFormValue.qualification}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
                ref={inputRef9}
                required
              >
                <option value="" selected disabled>
                  Select qualification
                </option>
                <option value="S">Secondary Level</option>
                <option value="H">Higher Secondary Level</option>
                <option value="B">Bachelor's Degree</option>
                <option value="M">Master Degree</option>
                <option value="P">PHD</option>
              </select>
              <i
                style={{ paddingTop: "4px", color: "#999" }}
                className="fas fa-angle-down notificationpositondrop"
              ></i>
              <p className="errormsg ">{memberFormErrors.qualification}</p>
            </div>



            <div className="form-group col-md-4">
              <label className="form-label" htmlFor="profession">
                Select Profession<sup className="sup-col">*</sup>
              </label>
              <select
                id="profession"
                name="profession"
                onChange={handleChange}
                value={memberFormValue.profession}
                className="form-control form-control-sm mb-1"
                style={{ fontSize: "12px" }}
                ref={inputRef9}
                required
              >
                <option value="" selected disabled>
                  Select Profession
                </option>
                {professionDrop.map((item) => (
                  
                  <option key={item.ID} value={item.ID}>
                    {item.Name}
                  </option>
                ))}
              </select>
              <i
                style={{ paddingTop: "4px", color: "#999" }}
                className="fas fa-angle-down notificationpositondrop"
              ></i>
              <p className="errormsg ">{memberFormErrors.profession}</p>
            </div>



            <div className=" col-md-4">
              <label className="form-label" htmlFor="pan">
                PAN
              </label>

              <input
                type="text"
                name="pan"
                onChange={handleChange}
                value={memberFormValue.pan}
                className="form-control form-control-sm mb-1"
                id="pan"
              />
            </div>

            <div className="form-group col-md-4">
              <label className="form-label" htmlFor="father">
                Father's Name
              </label>

              <input
                type="text"
                name="father"
                onChange={handleChange}
                value={memberFormValue.father}
                className="form-control form-control-sm mb-1"
                id="father"
              />
            </div> 

            </div>

          <div className="form-row">
            

            <div className="form-group col-md-4  ">
              <label className="form-label" htmlFor="mother">
                Mother's Name
              </label>
              <input
                type="text"
                name="mother"
                onChange={handleChange}
                value={memberFormValue.mother}
                className="form-control form-control-sm mb-1"
                id="mother"
              />
            </div> 

            <div className="form-group col-md-4  ">
              <label className="form-label" htmlFor="grandfather">
                Grandfather's Name
              </label>
              <input
                type="text"
                name="grandfather"
                onChange={handleChange}
                value={memberFormValue.grandfather}
                className="form-control form-control-sm mb-1"
                id="grandfather"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="form-label" htmlFor="text">
              Upload Image
            </div>
            <div className="BoxUpload">
              <div className="image-upload">
                {!isUploaded ? (
                  <>
                    <label htmlFor="upload-input">
                      <img
                        src={Plus}
                        draggable={"false"}
                        alt="placeholder"
                        style={{ width: 90, height: 100, paddingTop: "10px" }}
                      />
                    </label>

                    <input
                      id="upload-input"
                      type="file"
                      accept=".jpg,.jpeg,.gif,.png,.mov,.mp4"
                      onChange={handleImageChange}
                    />
                  </>
                ) : (
                  <div className="ImagePreview">
                    <img
                      className="close-icon"
                      src={CloseIcon}
                      alt="CloseIcon"
                      onClick={() => {
                        setIsUploaded(false);
                        setImage(null);
                      }}
                    />

                    <img
                      id="uploaded-image"
                      src={image}
                      draggable={false}
                      alt="uploaded-img"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* <div className="col-lg-12 personalFormFooter">
                <button className='btn btn-sm btn-cmpy ml-2' style={{ background: "green" }} onClick={formNext} >Next</button>
                <button className='btn btn-sm btn-cmpy ml-2' onClick={closePopup}>Cancel</button>
            </div> */}
      <div className="col-lg-12 Member--Footer">
        <div className="checkbox-close">
          <input
            type="checkbox"
            className="form-check-input"
            id="exampleCheck2"
            name="closeChecked"
            onChange={handleOnChange}
            checked={closeChecked}
          />
          <label class="form-check-label" for="exampleCheck2">
            Exit after submit
          </label>
        </div>
        <div className="form--footer">
          <button
            className="btn btn-sm btn-cmpy ml-2"
            style={{ background: "green" }}
            onClick={formNext}
          >
            Next
          </button>
          <button className="btn btn-sm btn-cmpy ml-2" onClick={closePopup}>
            Cancel
          </button>
        </div>
      </div>
      <RelationPopup
        relationPopup={relationPopup}
        setRelationPopup={setRelationPopup}
      />
    </>
  );
}
