import React, { useContext, useEffect, useState, useRef } from "react";
import "../../css/Complain.css";
import Spinner from "../Spinner/Spinner";
import ViewMemberPop from "./ViewMemberPop";
import MemberContext from "../contextFolder/MemberContextFolder/MemberContext";
import { showImgPreview } from "../hooks/ImagePreview";
import SidebarContext from "../sidebarcontext/SidebarContext";
import ResetPassword from "./ResetPassword";
import MemberDelete from "./MemberDelete";


const MemberTable = ({ searchTerm }) => {
  const { mode, reload, tableHeadBg, tableDataBg, darkText, setMsg } =
    useContext(SidebarContext);
  const [imgPrv, setImgPrv] = useState(false);
  const [imagePre, setImagePre] = useState("");

  const context = useContext(MemberContext);
  const {
    viewPop,
    setViewPop,
    disallowMember,
    handleEdit,
    loading,
    memberList,
    handleResetTrue,
    handleResetFalse,
    resetPopup,
    handleReset,
    re,
    memberStatus,
    handleDeleteTrue,
    handleDeleteFalse,
    delPopup,
    handleDelete,
    generatedPassword
  } = context;

  //chnage activated and deactivated
  const changeStatus = (id, AllowApp) => {
    disallowMember(id, AllowApp);
  };

  const checkStatus = (AllowApp) => {
    if (AllowApp === "Y") {
      return "Disallow";
    } else if (AllowApp === "N") {
      return "Allow";
    }
  };
  const componentRef = useRef();
  const ref = React.createRef();
  const options = {
    orientation: "landscape",
    unit: "in",
    format: [12, 12],
  };

  // to verify and unverify
  const handleVerify = (id) => {
    memberStatus(id, 1);
    setMsg({
      msg: "Verified",
      type: "alert alert-success",
    });
  };
  const handleUnverify = (id) => {
    memberStatus(id, 2);
    setMsg({
      msg: "Restricted",
      type: "alert alert-danger",
    });
  };
  // console.log("coco", memberList);

  if (memberList.length > 0) {
    return (
      <>
        <div className="outer-wrapper">
          <div
            className="table-wrapper"
            style={{
              overflowX: "scroll",
              overflowY: "scroll",
              margin: "3px",
              height: "65vh",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <div>
                <div className="table__button__wrapper">
                  {/* <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText={
                      <RiFileExcel2Fill
                        style={{ color: mode === "light" ? "#555" : "#fff" }}
                      />
                    }
                  /> */}

                  {/* <Pdf
                    targetRef={ref}
                    filename="code-example.pdf"
                    options={options}
                    x={0.5}
                    y={0.5}
                    scale={0.8}
                  >
                    {({ toPdf }) => (
                      <button onClick={toPdf}>
                        <BsFileEarmarkPdfFill
                          style={{ color: mode === "light" ? "#555" : "#fff" }}
                        />
                      </button>
                    )}
                  </Pdf> */}

                  {/* <ReactToPrint
                    trigger={() => {
                      return (
                        <button>
                          <AiFillPrinter
                            style={{
                              color: mode === "light" ? "#555" : "#fff",
                            }}
                          />
                        </button>
                      );
                    }}
                    content={() => componentRef.current}
                    documentTitle="new document"
                    pageStyle="print"
                  /> */}
                </div>
                <div ref={ref}>
                  <table
                    // ref={componentRef}
                    className="table "
                    // id="table-to-xls"
                    // key={re}
                  >
                    <thead>
                      <tr className="tableHead">
                        <td className="text-center" style={tableHeadBg}>
                          S.N.
                        </td>
                        {/* <td className="tl">Image</td> */}
                        <td style={tableHeadBg}>Full Name</td>
                        <td style={tableHeadBg}>Image</td>
                        {/* <td style={tableHeadBg}>User Name</td> */}
                        <td style={tableHeadBg}>House No</td>
                        <td style={tableHeadBg}>Contact</td>
                        <td style={tableHeadBg} className="w2">
                          {" "}
                          Allow App
                        </td>
                        <td style={tableHeadBg} className="w1">
                          {" "}
                          Action
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {memberList
                        .filter((val) => {
                          if (searchTerm === "") {
                            return val;
                          } else if (
                            val.MemberName.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            ) ||
                            val.HouseNo.toLowerCase().includes(
                              searchTerm.toLowerCase()
                            )
                          ) {
                            return val;
                          }
                        })
                        .map((item, i) => (
                          <tr key={i}>
                            <td style={tableDataBg} className="text-center">
                              {i + 1}
                            </td>
                            {/* <td className="contentLogo tc">
                              <div className="mgmtImg tc">
                                <img
                                  src={item.Photo}
                                  onClick={() => {
                                    setImagePre(item.Photo);
                                    setImgPrv(true);
                                  }}
                                  alt=""
                                />
                              </div>
                            </td> */}
                            <td style={tableDataBg}>{item.FullName}</td>

                            <td style={tableDataBg} className="contentLogo ">
                            <div className="mgmtImg ">
                              <img
                                src={item.UserImage}
                                alt="slider"
                                onClick={() => {
                                  setImagePre(item.UserImage);
                                  setImgPrv(true);
                                }}
                              />{" "}
                            </div>
                          </td>

                            {/* <td style={tableDataBg}>{item.UserName}</td> */}
                            <td style={tableDataBg}>{item.HouseNo}</td>
                            <td style={tableDataBg}> {item.Contact}</td>
                            <td style={tableDataBg} className="w2">
                            
                              <span
                                className="editspan badge"
                                onClick={() =>
                                  changeStatus(item.UserID, item.AllowApp)
                                }
                                style={darkText}
                              >
                                {checkStatus(item.AllowApp)}
                              </span>
                            </td>


                            <td style={tableDataBg} className="w1">
                            {/* <span
                                className="rejectspan badge"
                                onClick={() => handleEdit(item)}
                                style={darkText}
                              >
                                View
                              </span> */}

                              <span
                                className="rejectspan badge"
                                onClick={() => handleEdit(item)}
                                style={darkText}
                              >
                                Edit
                              </span>

                              <span
                                className="solvespan badge"
                                onClick={() => {
                                  handleReset(item);
                                }}
                                style={darkText}
                              >
                                Reset Password
                              </span>


                              {/* <span
                                className="solvespan badge"
                                onClick={() => {
                                  handleReset(item);
                                }}
                                style={darkText}
                              >
                                Change Image
                              </span> */}


                            <span
                              className="rejectspan badge"
                              onClick={() => {
                                handleDelete(item.UserID);
                              }}
                              style={darkText}
                            >
                              Remove
                            </span>


                              </td>


                            {/* <td style={tableDataBg} className="w2">
                              <>
                                {item.IsVerified === null ? (
                                  <>
                                    <span
                                      className="rejectspan badge"
                                      onClick={() =>
                                        handleVerify(item.MemberID)
                                      }
                                      style={darkText}
                                    >
                                      Verify
                                    </span>

                                    <span
                                      className="solvespan badge"
                                      onClick={() =>
                                        handleUnverify(item.MemberID)
                                      }
                                      style={darkText}
                                    >
                                      Cancel
                                    </span>
                                  </>
                                ) : item.IsVerified === 1 ? (
                                  "Approved"
                                ) : item.IsVerified === 2 ? (
                                  "Restricted"
                                ) : (
                                  ""
                                )}
                              </>
                            </td> */}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
        <ViewMemberPop memberTrigger={viewPop} setMemberTrigger={setViewPop} />
        {resetPopup?.show && (
  <ResetPassword
    handleResetTrue={handleResetTrue}
    handleResetFalse={handleResetFalse}
    generatedPassword={true}
  />
)}

{delPopup?.show && (
  <MemberDelete
    handleDeleteTrue={handleDeleteTrue}
    handleDeleteFalse={handleDeleteFalse}
  />
)}

      </>
    );
  } else {
    return (
      <div className="outer-wrapper">
        <div
          className="table-wrapper"
          style={{
            overflowX: "scroll",
            overflowY: "scroll",
            margin: "3px",
            height: "400px",
          }}
        >
          <div>
            <div className="table__button__wrapper">
              {/* <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="table-to-xls"
                filename="tablexls"
                sheet="tablexls"
                buttonText={
                  <RiFileExcel2Fill
                    style={{ color: mode === "light" ? "#555" : "#fff" }}
                  />
                }
              />

              <Pdf
                targetRef={ref}
                filename="code-example.pdf"
                options={options}
                x={0.5}
                y={0.5}
                scale={0.8}
              >
                {({ toPdf }) => (
                  <button onClick={toPdf}>
                    <BsFileEarmarkPdfFill
                      style={{ color: mode === "light" ? "#555" : "#fff" }}
                    />
                  </button>
                )}
              </Pdf>

              <ReactToPrint
                trigger={() => {
                  return (
                    <button>
                      <AiFillPrinter
                        style={{ color: mode === "light" ? "#555" : "#fff" }}
                      />
                    </button>
                  );
                }}
                content={() => componentRef.current}
                documentTitle="new document"
                pageStyle="print"
              /> */}
                 {/* {imgPrv &&
          showImgPreview({
            img: imagePre,
            setTrigger: setImgPrv,
          })} */}
            </div>
            <div ref={ref}>
              <table ref={componentRef} className="table" id="table-to-xls">
                <thead>
                  <tr className="tableHead">
                    <td style={tableHeadBg} className="text-center">
                      S.N.
                    </td>
                    <td style={tableHeadBg}>Image</td>
                    <td style={tableHeadBg}>Full Name</td>
                    <td style={tableHeadBg}>User Name</td>
                    <td style={tableHeadBg}>House No</td>
                    <td style={tableHeadBg}>Family Number</td>
                    <td style={tableHeadBg} className="w1">
                      {" "}
                      Action
                    </td>
                    <td style={tableHeadBg} className="w2">
                      {" "}
                      Status
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div className="text-center mt-4">
            <p style={darkText}>No data found</p>
          </div>
        </div>
      </div>
    );
  }
};

export default MemberTable;
